import TextField from "@material-ui/core/TextField";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { Autocomplete } from "@mui/material";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SimpleFileUpload from "react-simple-file-upload";
import { BASE_URL, get, post, put } from "../../Services/Calls";
import { Header } from "../../components";
import CrossIcon from "../../data/close.png";
import DropDownArrow from "../../data/rightArrow.png";
import "./NewMain.css";

import Select from "react-select";
import { notify, SimpleFileUploadAPIKey } from "../../Services/Utils";
import BlueCheck from "../../data/blueCheck.png";


const CreateSelfGuided = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [imageName, setImageName] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [fromStatus, setFromStatus] = useState("");
  const [selfGuidedId, setSelfGuidedId] = useState("");
  const [showImagePopUp, setShowImagePopUp] = useState(false);

  const [showTripSelect, setShowTripSelect] = useState(false);
  const [trip, setTrip] = useState();
  const [name, setName] = useState('');
  const [tripData, setTripData] = useState([]);

  const [formFirstName, setFormFirstName] = useState("");
  const [formLastName, setFormLastName] = useState("");
  const [formEmail, setFormEmail] = useState("");

  const [hotelData, setHotelData] = useState([]);
  const [dayshotelData, setDaysHotelData] = useState([]);

  const [option, setOption] = useState([]);

  const [voucher, setVoucher] = useState([]);
  const [loading, setLoading] = useState(false);

  const [contentEmail, setContentEmail] = useState("");
  const [hotelsData, setHotelsData] = useState([]);
  const [overnightPreNight, setOvernightPreNight] = useState([]);
  const [overnightPostNight, setOvernightPostNight] = useState([]);
  const [selectedOptionPre, setSelectedOptionPre] = useState(null);
  const [selectedOptionPost, setSelectedOptionPost] = useState(null);
  const [overnightPreNightId, setOvernightPreNightId] = useState("");
  const [overnightPostNightId, setOvernightPostNightId] = useState("");
  const [overnightPreHotelSiteLink, setOvernightPreHotelSiteLink] =
    useState("");
  const [overnightPostHotelSiteLink, setOvernightPostHotelSiteLink] =
    useState("");

  const [price, setPrice] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [tripsOptions, setTripsOptions] = useState([]);
  const [travellersNumber, setTravellersNumber] = useState(null);
  const [personalized, setPersonalized] = useState(true);
  const [currency, setCurrency] = useState({});
  const [currencyData, setCurrencyData] = useState([]);
  const [languageData, setLanguageData] = useState([]);
  const [langEngHebrew, setLangEngHebrew] = useState([]);
  const [languageSelected, setLanguageSelected] = useState("english (uk)");
  const [showCurrencySelect, setShowCurrencySelect] = useState(false);
  const [showLanguageSelect, setShowLanguageSelect] = useState(false);
  const [hasAddons, setHasAddons] = useState(false);
  const [addonTitle, setAddonTitle] = useState("");
  const [addons, setAddons] = useState([
    { name: "", checked: false, language: "", languageId: "" },
  ]);
  const [users, setUsers] = useState([
    { firstName: "", lastName: "", email: "" },
  ]);
  const [setInvitations, setSetInvitations] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [usersInvitationId, setUsersInvitationIds] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [friendlyId, setFriendlyId] = useState('');

  const handleDateChange = (args) => {
    setSelectedDate(args.value);
  };

  const handleDateEndChange = (args) => {
    setSelectedEndDate(args.value);
  };

  const [priceDescription, setPriceDescription] =
    useState(`<ul style="list-style-type: disc; padding-inline-start: 40px;">
    <li><span style="font-size: 18px;"><span style="font-size: 16px;">&nbsp;Price is based on 2 people sharing a double or twin room</span></span></li>
    <li><span style="font-size: 18px;"><span style="font-size: 16px;">&nbsp;Adjustments can be made for group size (single, triple, family), car category, level of accommodation</span></span></li>
    <li><span style="font-size: 18px;"><span style="font-size: 16px;">&nbsp;Price is based on 4&nbsp;people per car</span></span></li>
</ul>`);
  const [priceDescriptionHebrew, setPriceDescriptionHebrew] =
    useState(`<ul style="list-style-type: disc; padding-inline-start: 40px;">
    <li><span style="font-size: 18px;"><span style="font-size: 16px;">&nbsp;המחיר מבוסס על שני מטיילים בחדר זוגי</span></span></li>
    <li><span style="font-size: 18px;"><span style="font-size: 16px;">&nbsp;ניתן לבצע התאמות של התוכנית למשך הטיול / גודל הקבוצה וכו׳</span></span></li>
    <li><span style="font-size: 18px;"><span style="font-size: 16px;">&nbsp;המחיר מבוסס על 4 מטיילים בג׳יפ</span></span></li>
</ul>`);

  const [priceIncludes, setPriceIncludes] =
    useState(`<ul style="list-style-type: disc; padding-inline-start: 40px;">
    <li><span style="font-size: 18px;"><span style="font-size: 16px;">&nbsp;8 nights at good 3* - 4* hotels including breakfast</span></span></li>
    <li><span style="font-size: 16px;">&nbsp;Car model Hyundai Tucson or similar for 9 days<br>
            (A local guide or driver is optional with an additional payment)</span></li>
    <li><span style="font-size: 16px;">&nbsp;Local Hero pre-trip advice session</span></li>
    <li><span style="font-size: 16px;">&nbsp;Indie4X4 App Guide premium package includes:</span>
        <ul style="list-style-type: circle;padding-inline-start: 40px;padding-top: 10px">
            <li><span style="font-size: 16px;">&nbsp; &nbsp;Navigation system with Your day-by-day personal itinerary</span></li>
            <li><span style="font-size: 16px;">&nbsp; &nbsp;Points of interest</span></li>
            <li><span style="font-size: 16px;">&nbsp; &nbsp;"Local Hero" tips based on location. Audio and Visual</span></li>
            <li><span style="font-size: 16px;">&nbsp; &nbsp;Hiking trails nearby, curated by location, difficulty, distance</span></li>
            <li>&nbsp; &nbsp;Local hero human support during your tour (9:00-18:00 destination time)<br></li>
            <li><span style="font-size: 16px;">&nbsp; &nbsp;Offline navigation, anywhere, anytime</span></li>
        </ul>
    </li>
</ul>`);
  const [priceIncludesHebrew, setPriceIncludesHebrew] =
    useState(`<ul style="list-style-type: disc; padding-inline-start: 40px;">
    <li><span style="font-size: 18px;"><span style="font-size: 16px;">&nbsp;8 לילות במלונות טובים 3* - 4*</span></span></li>
    <li><span style="font-size: 16px;">&nbsp;דגם הרכב Hyundai Tucson או דומה ל- 9 ימים<br>
            (מדריך מקומי או נהג הוא אופציונלי עם תשלום נוסף)</span></li>
    <li><span style="font-size: 16px;">&nbsp;כלכלה ע״ב א. בוקר</span></li>
    <li><span style="font-size: 16px;">&nbsp;פעילויות</span></li>
    <li><span style="font-size: 16px;">&nbsp;מפגש מקדים עם מדריך שטח</span></li>
    <li><span style="font-size: 16px;">&nbsp;ערכת המדריך הוירטואלי Indie App Guide הכוללת:</span>
        <ul style="list-style-type: circle;padding-inline-start: 40px;padding-top: 10px">
            <li><span style="font-size: 16px;">&nbsp; &nbsp;תוכנת ניווט שטח עם מסלול הטיול האישי</span></li>
            <li><span style="font-size: 16px;">&nbsp; &nbsp;ניווט לא מקוון (אופליין) בכל מקום בכל זמן</span></li>
            <li><span style="font-size: 16px;">&nbsp; &nbsp;נקודות העניין המופיעות בתוכנית</span></li>
            <li><span style="font-size: 16px;">&nbsp; &nbsp;טיפים ומידע המופיעים על בסיס המיקום בזמן הטיול</span></li>
            <li><span style="font-size: 16px;">&nbsp; &nbsp;מסלולי הליכה סמוכים לאזור הטיול, מסודרים לפי דרגות קושי ומרחק הליכה</span></li>
            <li>&nbsp; &nbsp;תמיכה רציפה של המשרד המקומי והמשרד הישראלי</li>
        </ul>
    </li>
</ul>`);

  const [excluded, setExcluded] =
    useState(`<ul style="list-style-type: disc; padding-inline-start: 40px;">
    <li><span style="font-size: 18px;"><span style="font-size: 16px;">&nbsp; &nbsp;Airfare and transfers</span></span></li>
    <li><span style="font-size: 16px;">&nbsp;&nbsp;
            Lunches, dinners, drinks
            and snacks</span></li>
    <li><span style="font-size: 16px;">&nbsp;&nbsp; Pick season supplement (if needed)</span></li>
    <li><span style="font-size: 16px;">&nbsp; &nbsp;Car rental is subject to the terms and conditions of local rental companies, and local laws</span></li>
    <li><span style="font-size: 16px;">&nbsp;&nbsp;
            Personal expenses</span></li>
    <li><span style="font-size: 16px;">&nbsp;&nbsp; Local tourist taxes and
            entry fees (payable on-site)</span><span style="font-size: 16px;">&nbsp;&nbsp;</span></li>
    <li><span style="font-size: 16px;">&nbsp; &nbsp;Any items not explicitly
            listed as included</span></li>
    </ul>`);
  const [excludedHebrew, setExcludedHebrew] =
    useState(`<ul style="list-style-type: disc; padding-inline-start: 40px;">
    <li><span style="font-size: 18px;"><span style="font-size: 16px;">&nbsp; &nbsp;טיסות</span></span></li>
    <li><span style="font-size: 16px;">&nbsp;&nbsp;הוצאות אישיות</span></li>
    <li><span style="font-size: 16px;">&nbsp;&nbsp;תוספת קיץ וחג</span></li>
    <li><span style="font-size: 16px;">&nbsp; &nbsp;מיסי תיירות מקומיים ודמי כניסה</span></li>
    <li><span style="font-size: 16px;">&nbsp;&nbsp;כל הפריטים שאינם ברשימת ״המחיר כולל״</span></li>
</ul>`);

  const [confirmPolicy, setConfirmPolicy] =
    useState(`<ul style="list-style-type: disc; padding-inline-start: 40px;">
    <li><span style="font-size: 18px;"><span style="font-size: 16px;">&nbsp;Cancellation must be made 24 hours prior to check-in for a full refund</span></span></li>
    <li><span style="font-size: 18px;"><span style="font-size: 16px;">&nbsp;No refunds for cancellations made within 7 days of the reservation date</span></span></li>
    <li><span style="font-size: 18px;"><span style="font-size: 16px;">&nbsp;Non-refundable package</span></span></li>
</ul>`);
  const [confirmPolicyHebrew, setConfirmPolicyHebrew] =
    useState(`<ul style="list-style-type: disc; padding-inline-start: 40px;">
    <li><span style="font-size: 18px;"><span style="font-size: 16px;">&nbsp;ביטול יידרש להתבצע 24 שעות לפני זמן הצ'ק-אין כדי לקבל החזר מלא</span></span></li>
    <li><span style="font-size: 18px;"><span style="font-size: 16px;">&nbsp;אין החזרים עבור ביטולים שנעשו פחות מ-7 ימים לפני תאריך ההזמנה</span></span></li>
    <li><span style="font-size: 18px;"><span style="font-size: 16px;">&nbsp;חבילה בלתי ניתנת להחזר</span></span></li>
</ul>
`);

  useEffect(() => {
    (async () => {
      await getTrips();
      await getCurrency();
      await getHotelData();
      await getLanguages();
    })();
  }, []);

  const getTrips = async () => {
    try {
      const response = await get(BASE_URL + "/trips");

      if (response && response.results && response.results.length > 0) {
        setTripData(response.results);
      }
    } catch (ex) {
      alert(ex);
    }
  };

  const getCurrency = async () => {
    try {
      const response = await get(BASE_URL + "/currencies");

      if (response && response.length > 0) {
        setCurrencyData(response);
      }
    } catch (ex) {
      alert(ex);
    }
  };

  const getLanguages = async () => {
    try {
      const response = await get(BASE_URL + "/languages");

      if (response && response.length > 0) {
        setLanguageData(response);
        const languagesENHE = response.filter(language => {
          const nameLower = language?.name?.toLowerCase();
          return nameLower.includes('hebrew') || nameLower.includes('english');
        });
        setLangEngHebrew(languagesENHE);
      }
    } catch (ex) {
      alert(ex);
    }
  };

  const getLocalExpertData = async () => {
    try {
      const response = await get(BASE_URL + `/local-experts/country/${trip.country_id}`);
      if (response) {
        return response
      }
    } catch (ex) {
      alert(ex);
    }
  };

  const getHotelData = async () => {
    try {
      const response = await get(BASE_URL + "/hotels-prices-list");
      if (response && response.length > 0) {
        setHotelData(response);

        let tempOption = [];
        for (let i = 0; i < response.length; i++) {
          let hotelId = response[i].friendly_id ? response[i].friendly_id : '';
          let hotelName = response[i].hotel_name ? response[i].hotel_name : '';
          let hotelCountry = (response[i].country && response[i].country.name) ? response[i].country.name : '';
          let hotelCity = response[i].overnight_city ? response[i].overnight_city : '';
          let label = `ID: ${hotelId}`;
          if (hotelName) label += `, ${hotelName}`;
          if (hotelCountry) label += `, ${hotelCountry}`;
          if (hotelCity) label += `, ${hotelCity}`;
          let json = {
            value: response[i].hotel_name,
            label,
            id: response[i].id,
            data: response[i],
          };
          tempOption.push(json);
        }

        setOption(tempOption);
      }
    } catch (ex) {
      alert(ex);
    }
  };

  const setAddonsData = async (packageId) => {
    try {
      const response = await get(BASE_URL + "/addon/package/" + packageId);
      if (response) {
        setHasAddons(true);
        if (response.texts && response.texts.length > 0) {
          const formattedAddons = response.texts.map((addonData, i) => {
            return {
              name: addonData.text,
              checked: false,
              language: addonData.language,
              languageId: addonData.languageId,
            };
          });
          setAddonTitle(response.title);
          setAddons(formattedAddons);
        } else {
          console.log('No addons data');
        }
      } else {
        console.log('No addons found');
      }
    } catch (ex) {
      console.error("Error fetching addons data:", ex);
    }
  };

  const setUserData = async (data) => {
    if (data && data.length > 0) {
      const userData = JSON.parse(data);
      if (userData && userData.length > 0) {
        for (const item of userData) {
          setFormFirstName(item.formFirstName);
          setFormLastName(item.formLastName);
          setFormEmail(item.formEmail);
        }
      }
    }
  };

  const setDaysDataHotels = async (data, sgpTrip) => {
    if ((data && data.length > 0) && (sgpTrip && sgpTrip.days.length > 0)) {
      const daysData = JSON.parse(data)
      const dataHotels = [];
      if (daysData && daysData.length > 0 && sgpTrip) {
        for (const item of daysData) {
          try {
            if (item.hotelId && item.hotelId !== 'null') {
              const response = await get(
                BASE_URL + "/hotels-prices-list/" + item.hotelId
              );
              if (response) {
                const index = item.dayIndex ? item.dayIndex : daysData.indexOf(item);
                let hotelId = response.friendly_id ? response.friendly_id : '';
                let hotelName = response.hotel_name ? response.hotel_name : '';
                let hotelCountry = (response.country && response.country.name) ? response.country.name : '';
                let hotelCity = response.overnight_city ? response.overnight_city : '';
                let label = `ID: ${hotelId}`;
                if (hotelName) label += `, ${hotelName}`;
                if (hotelCountry) label += `, ${hotelCountry}`;
                if (hotelCity) label += `, ${hotelCity}`;
                let json = {
                  value: response.hotel_name,
                  label,
                  id: response.id,
                  data: response,
                  dayIndex: index
                };
                dataHotels.push(json);
              }

              handleSetHotelData(dataHotels, sgpTrip)

              if (item.preHotelId && item.preHotelId !== 'null') {
                const responsePre = await get(
                  BASE_URL + "/hotels-prices-list/" + item.preHotelId
                );
                let hotelId = responsePre.friendly_id ? responsePre.friendly_id : '';
                let hotelName = responsePre.hotel_name ? responsePre.hotel_name : '';
                let hotelCountry = (responsePre.country && responsePre.country.name) ? responsePre.country.name : '';
                let hotelCity = responsePre.overnight_city ? responsePre.overnight_city : '';
                let label = `ID: ${hotelId}`;
                if (hotelName) label += `, ${hotelName}`;
                if (hotelCountry) label += `, ${hotelCountry}`;
                if (hotelCity) label += `, ${hotelCity}`;
                let jsonPre = {
                  value: responsePre.hotel_name,
                  label,
                  id: responsePre.id,
                  data: responsePre,
                };
                setSelectedOptionPre(jsonPre);
                setOvernightPreNight(responsePre.overnight_city);
                setOvernightPreNightId(responsePre.id);
                setOvernightPreHotelSiteLink(responsePre.site_link);
              }

              if (item.postHotelId && item.postHotelId !== 'null') {
                const responsePost = await get(
                  BASE_URL + "/hotels-prices-list/" + item.postHotelId
                );
                let hotelId = responsePost.friendly_id ? responsePost.friendly_id : '';
                let hotelName = responsePost.hotel_name ? responsePost.hotel_name : '';
                let hotelCountry = (responsePost.country && responsePost.country.name) ? responsePost.country.name : '';
                let hotelCity = responsePost.overnight_city ? responsePost.overnight_city : '';
                let label = `ID: ${hotelId}`;
                if (hotelName) label += `, ${hotelName}`;
                if (hotelCountry) label += `, ${hotelCountry}`;
                if (hotelCity) label += `, ${hotelCity}`;
                let jsonPost = {
                  value: responsePost.hotel_name,
                  label,
                  id: responsePost.id,
                  data: responsePost,
                };
                setSelectedOptionPost(jsonPost);
                setOvernightPostNight(responsePost.overnight_city);
                setOvernightPostNightId(responsePost.id);
                setOvernightPostHotelSiteLink(responsePost.site_link);
              }
              if (item.contentEmail && item.contentEmail !== 'null') {
                setContentEmail(item.contentEmail);
              }
            }
          } catch (error) {
            console.error("Error fetching hotel data:", error);
          }
        }
      }
      setDaysHotelData(dataHotels);
    }
  };

  const handleSetHotelData = (hotelData, tripData) => {
    let tempTrip = { ...tripData };
    if (tempTrip && tempTrip.days && tempTrip.days.length > 0) {
      hotelData.forEach(hotel => {
        if (tempTrip[hotel.dayIndex]) {
          tempTrip[hotel.dayIndex].hotels = hotel.data;
        } else {
          console.error(`Day with index ${hotel.dayIndex} does not exist in tempTrip.`);
        }
      });
    } else {
      console.error('No days found in tempTrip.');
    }
    setTrip(tempTrip);
    setHotelsData(hotelData);
  };

  const handleChangeHotel = (hotelOption, day, i, tripData) => {
    let selectedData = day;
    selectedData.hotels = hotelOption.data;

    let tempTrip = { ...tripData };
    if (tempTrip && tempTrip.days && tempTrip.days.length > 0) {
      for (let j = 0; j < tempTrip.days.length; j++) {
        if (tempTrip.days[j].id == day.id) {
          tempTrip.days[j] = selectedData;
        }
      }
      const updatedHotels = [...hotelsData];
      const newHotelObject = JSON.parse(JSON.stringify(hotelOption));
      newHotelObject.dayIndex = i;
      updatedHotels[i] = newHotelObject;
      setHotelsData(updatedHotels);
      setTrip(tempTrip);
    }
  };

  useEffect(() => {
    if (location && location.state && location.state.from) {
      setFromStatus(location.state.from);
      if (location.state.data && location.state.data.id) {
        setConfirmPolicy(location.state.data.confirmation_policy);
        setConfirmPolicyHebrew(location.state.data.confirmation_policy_hebrew);
        setCurrency(location.state.data.currency);
        setExcluded(location.state.data.excluded);
        setExcludedHebrew(location.state.data.excluded_hebrew);
        setSelfGuidedId(location.state.data.id);
        setTravellersNumber(location.state.data.number_travelers);
        setPersonalized(location.state.data.personalized);
        setImageName(location.state.data.photo);
        setPrice(location.state.data.price);
        setPriceDescription(location.state.data.price_description);
        setPriceIncludes(location.state.data.price_includes);
        setPriceDescriptionHebrew(location.state.data.price_description_hebrew);
        setPriceIncludesHebrew(location.state.data.price_includes_hebrew);
        setSelectedDate(location.state.data.starting_date);
        setSelectedEndDate(location.state.data.ending_date);
        setFriendlyId(location.state.data.friendlyId);
        const trip = location.state.data.trip;

        // Create a map from daysSort to index for quick lookup
        const sortOrderMap = new Map(trip.days_sort.map((id, index) => [id, index]));

        // Sort the days array according to the sortOrderMap
        trip.days.sort((a, b) => sortOrderMap.get(a.id) - sortOrderMap.get(b.id));

        setTrip(trip);

        const packageName = location.state.data.name
          ? `${location.state.data.name}`
          : "";
        setName(packageName);
        setVoucher(location.state.data.vouchers);
        const language = location.state.data.language?.toLowerCase();
        setLanguageSelected(language);
        setAddonsData(location.state.data.id);
        setDaysDataHotels(location.state.data.days_data, location.state.data.trip);
        setUserData(location.state.data.user_data);
        const tripName = location.state.data.trip.name && location.state.data.trip.friendly_id
          ? `${location.state.data.trip.name}-${location.state.data.trip.friendly_id}`
          : "no trip name";
        if (location.state.data.trip.name && location.state.data.trip.friendly_id) {
          setSearchTerm(tripName);
          tripSelectFun(tripName);
        }
      }
    }
    window.scrollTo(0, 0);
  }, []);

  async function CreateSelfGuidedFun() {
    if (trip && trip.id) {
    } else {
      setErrorMsg("Please Select Trip.");
      return;
    }

    if (currency && currency.id) {
    } else {
      setErrorMsg("Please Select Currency.");
      return;
    }

    try {
      const hotelsIds = [];
      let daysData = []
      if (hotelsData && hotelsData.length > 0) {
        daysData = hotelsData.map((item) => {
          hotelsIds.push(item.data.id);
          return {
            hotelName: item.data.hotel_name,
            hotelSite: item.data.site_link,
            dayIndex: item.dayIndex,
            hotelId: item.data.id,
            overnightPreNight: overnightPreNight,
            overnightPostNight: overnightPostNight,
            preHotelId: overnightPreNightId,
            postHotelId: overnightPostNightId,
            overnightPreHotelSiteLink: overnightPreHotelSiteLink,
            overnightPostHotelSiteLink: overnightPostHotelSiteLink,
            contentEmail: contentEmail,
          };
        });
      }

      const userData = [
        {
          formFirstName,
          formLastName,
          formEmail,
        },
      ];
      let imageSGP = "";
      if (imageName == "") {
        imageSGP = trip.image
      } else {
        imageSGP = imageName;
      }
      let json = {
        photo: imageSGP,
        price: parseFloat(price),
        price_description: priceDescription,
        price_includes: priceIncludes,
        excluded: excluded,
        confirmation_policy: confirmPolicy,
        price_description_hebrew: priceDescriptionHebrew,
        price_includes_hebrew: priceIncludesHebrew,
        excluded_hebrew: excludedHebrew,
        confirmation_policy_hebrew: confirmPolicyHebrew,
        trip: trip.id,
        currency: currency.id,
        language: languageSelected,
        number_travelers: parseFloat(travellersNumber),
        personalized: personalized,
        starting_date: selectedDate,
        ending_date: selectedEndDate,
        vouchers: voucher,
        days_data: JSON.stringify(daysData),
        user_data: JSON.stringify(userData),
        hotelsIds: hotelsIds,
        name
      };

      const response = await post(BASE_URL + "/self-guided-packages", json);
      if (response) {
        if (addons && addons[0].name.length > 0) {
          const filteredAddons = addons.filter((addon) => addon.checked);
          const formattedAddons = filteredAddons.map((addon) => {
            return {
              text: addon.name,
              languageId: addon.languageId,
              language: addon.language,
            };
          });
          let dataAddon = {
            title: addonTitle,
            packageId: response.data.id,
            texts: formattedAddons,
          };
          await post(BASE_URL + "/addon", dataAddon);
        }
        if (userData && userData.length > 0 && userData[0].formEmail) {
          const user = await get(
            BASE_URL + "/users/by-email/" + userData[0].formEmail
          );
          if (user && user.id) {
            let userUpdateData = {
              "active_trip_id": trip.id,
              "self_guided_package_id": response.data.id
            }
            await put(BASE_URL + "/users/" + user.id, userUpdateData);
          } else {
            try {
              const createUserData = {
                firstName: userData[0].formFirstName,
                lastName: userData[0].formLastName,
                email: userData[0].formEmail,
                password: '12345678',
                active_trip_id: trip.id,
                self_guided_package_id: response.data.id
              };
              await post(BASE_URL + "/users", createUserData);
            } catch (ex) {
              console.error("Error creating user:", ex);
              notify("Failed to create user.");
              return;
            }
          }
        }
        if (usersInvitationId) {
          try {
            await Promise.all(usersInvitationId.map(async (userId) => {
              let userUpdateInvitationData = {
                "active_trip_id": trip.id,
                "self_guided_package_id": response.data.id
              }
              await put(BASE_URL + "/users/" + userId, userUpdateInvitationData);
            }))
          } catch (ex) {
            console.error("Error update user:", ex);
            notify("Failed to update user.");
            return;
          }
        }
        notify(`Self-Guided-Package ${response.data.friendly_id} created successfully`);
        navigate("/selfGuided");
      }
    } catch (ex) {
      alert(ex);
      console.log(ex)
    }
  }

  async function editSelfGuidedFun() {
    if (!trip || !trip.id) {
      setErrorMsg("Please Select Trip.");
      return;
    }

    if (!currency || !currency.id) {
      setErrorMsg("Please Select Currency.");
      return;
    }
    const hotelsIds = [];
    let daysData = []
    if (hotelsData && hotelData.length > 0) {
      daysData = hotelsData.map((item) => {
        hotelsIds.push(item.data.id);
        return {
          hotelName: item.data.hotel_name,
          hotelSite: item.data.site_link,
          dayIndex: item.dayIndex,
          hotelId: item.data.id,
          overnightPreNight: overnightPreNight,
          overnightPostNight: overnightPostNight,
          preHotelId: overnightPreNightId,
          postHotelId: overnightPostNightId,
          overnightPreHotelSiteLink: overnightPreHotelSiteLink,
          overnightPostHotelSiteLink: overnightPostHotelSiteLink,
          contentEmail: contentEmail,
        };
      });
    }
    const userData = [
      {
        formFirstName,
        formLastName,
        formEmail,
      },
    ];
    let imageSGP = "";
    if (imageName == "") {
      imageSGP = trip.image
    } else {
      imageSGP = imageName;
    }
    try {
      let json = {
        photo: imageSGP,
        price: parseFloat(price),
        price_description: priceDescription,
        price_includes: priceIncludes,
        excluded: excluded,
        confirmation_policy: confirmPolicy,
        price_description_hebrew: priceDescriptionHebrew,
        price_includes_hebrew: priceIncludesHebrew,
        excluded_hebrew: excludedHebrew,
        confirmation_policy_hebrew: confirmPolicyHebrew,
        trip: trip.id,
        currency: currency.id,
        number_travelers: parseFloat(travellersNumber),
        personalized: personalized,
        starting_date: selectedDate,
        ending_date: selectedEndDate,
        language: languageSelected,
        vouchers: voucher,
        days_data: JSON.stringify(daysData),
        user_data: JSON.stringify(userData),
        hotelsIds: hotelsIds,
        name
      };

      const response = await put(
        BASE_URL + "/self-guided-packages/" + selfGuidedId,
        json
      );
      if (response) {
        if (addons && addons[0].name.length > 0) {
          const filteredAddons = addons.filter((addon) => addon.checked);
          const formattedAddons = filteredAddons.map((addon) => {
            return {
              text: addon.name,
              languageId: addon.languageId,
              language: addon.language,
            };
          });
          let dataAddon = {
            title: addonTitle,
            packageId: selfGuidedId,
            texts: formattedAddons,
          };
          if (hasAddons) {
            await put(BASE_URL + "/addon/" + selfGuidedId, dataAddon);
          } else {
            await post(BASE_URL + "/addon", dataAddon);
          }

        }
        if (userData && userData.length > 0 && userData[0].formEmail) {
          const user = await get(
            BASE_URL + "/users/by-email/" + userData[0].formEmail
          );
          if (user && user.id) {
            let userUpdateData = {
              "active_trip_id": trip.id,
              "self_guided_package_id": selfGuidedId
            }
            await put(BASE_URL + "/users/" + user.id, userUpdateData);
          } else {
            try {
              const createUserData = {
                firstName: userData[0].formFirstName,
                lastName: userData[0].formLastName,
                email: userData[0].formEmail,
                password: '12345678',
                active_trip_id: trip.id,
                self_guided_package_id: response.data.id
              }
              await post(BASE_URL + "/users", createUserData);
            } catch (ex) {
              console.error("Error creating user:", ex);
              notify("Failed to create user.");
              return;
            }
          }
        }

        notify(`Self-Guided-Package ${response.data.friendly_id} updated successfully`);
        navigate("/selfGuided");
      }
    } catch (ex) {
      alert(ex);
      console.log(ex);
    }
  }

  function handleUpload(url) {
    setImageName(url);
    setShowImagePopUp(false);
    setErrorMsg("");
  }

  function handleMultipleUpload(files) {
    let tempFiles =
      voucher && voucher.length > 0
        ? fromStatus == "edit"
          ? [...voucher]
          : []
        : [];
    for (let i = 0; i < files.length; i++) {
      tempFiles.push(files[i].cdnUrl);
    }
    setVoucher(tempFiles);
  }

  function deleteVoucher(item, index) {
    let tempFiles = [...voucher];
    for (let i = 0; i < tempFiles.length; i++) {
      if (tempFiles[i] == item) {
        tempFiles.splice(i, 1);
      }
    }
    setVoucher(tempFiles);
  }

  const handlePriceDesChange = (args) => {
    setPriceDescription(args.value);
  };
  const handlePriceIncludesChange = (args) => {
    setPriceIncludes(args.value);
  };
  const handlePriceExcludedChange = (args) => {
    setExcluded(args.value);
  };
  const handleConfirmPolicyChange = (args) => {
    setConfirmPolicy(args.value);
  };
  const handlePriceDesChangeHebrew = (args) => {
    setPriceDescriptionHebrew(args.value);
  };
  const handlePriceIncludesChangeHebrew = (args) => {
    setPriceIncludesHebrew(args.value);
  };
  const handlePriceExcludedChangeHebrew = (args) => {
    setExcludedHebrew(args.value);
  };
  const handleConfirmPolicyChangeHebrew = (args) => {
    setConfirmPolicyHebrew(args.value);
  };

  async function viewTemplate() {
    if (trip && trip.id) {
    } else {
      setErrorMsg("Please Select Trip.");
      return;
    }

    if (currency && currency.id) {
    } else {
      setErrorMsg("Please Select Currency.");
      return;
    }
    if (price && price != "") {
    } else {
      setErrorMsg("Please Enter Price.");
      return;
    }
    if (formFirstName == "") {
      setErrorMsg("Please Enter First Name.");
      return;
    }
    if (formLastName == "") {
      setErrorMsg("Please Enter Last Name.");
      return;
    }
    const localExpert = await getLocalExpertData();
    const filteredAddons = addons.filter((addon) => addon.checked);
    const formattedAddons = filteredAddons.map((addon) => {
      return {
        text: addon.name,
        languageId: addon.languageId,
        language: addon.language,
      };
    });
    let dataAddon = {
      title: addonTitle,
      packageId: selfGuidedId,
      texts: formattedAddons,
    };
    trip.days.forEach(day => {
      const allItems = [...day.gpx_set, ...day.poi_set];
      const sortOrder = day.poi_set_sort.split(',');

      day.gpxs_pois_sorted = sortOrder.map(id =>
        allItems.find(item => item.id === id)
      );
    });

    const dataToPass = {
      message: "Hello from Page1!",
      trip: trip,
      currency: currency,
      price: price,
      priceDescription: priceDescription,
      priceDescriptionHebrew: priceDescriptionHebrew,
      priceIncludes: priceIncludes,
      priceIncludesHebrew: priceIncludesHebrew,
      confirmPolicy: confirmPolicy,
      confirmPolicyHebrew: confirmPolicyHebrew,
      excluded: excluded,
      excludedHebrew: excludedHebrew,
      localExpertImage: localExpert.imageURL,
      formFirstName: formFirstName,
      formLastName: formLastName,
      overnightPreNight,
      overnightPreHotelSiteLink,
      selectedOptionPre: selectedOptionPre != null ? selectedOptionPre.value : '',
      overnightPostNight,
      overnightPostHotelSiteLink,
      selectedOptionPost: selectedOptionPost != null ? selectedOptionPost.value : '',
      languageSelected: languageSelected,
      dataAddon,
      hotelsData: hotelsData,
    };

    localStorage.setItem("sharedData", JSON.stringify(dataToPass));
    const newTab = window.open("/templateView", "_blank");
    newTab.focus();
  }

  async function sendEmailTemplate() {
    if (trip && trip.id) {
    } else {
      setErrorMsg("Please Select Trip.");
      return;
    }

    if (currency && currency.id) {
    } else {
      setErrorMsg("Please Select Currency.");
      return;
    }
    if (price && price != "") {
    } else {
      setErrorMsg("Please Enter Price.");
      return;
    }
    if (formFirstName == "") {
      setErrorMsg("Please Enter First Name.");
      return;
    }
    if (formLastName == "") {
      setErrorMsg("Please Enter Last Name.");
      return;
    }
    if (formEmail === "") {
      setErrorMsg("Email can't be empty.");
      return;
    }
    if (formEmail !== "") {
      let pattern =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (!pattern.test(formEmail)) {
        setErrorMsg("Invalid email format.");
        return;
      }
    }
    const filteredAddons = addons.filter((addon) => addon.checked);
    const formattedAddons = filteredAddons.map((addon) => {
      return {
        text: addon.name,
        languageId: addon.languageId,
        language: addon.language,
      };
    });

    let dataAddon = {
      title: addonTitle,
      packageId: selfGuidedId,
      texts: formattedAddons,
    };

    const sharedData = {
      message: "Hello from Page1!",
      trip: trip,
      currency: currency,
      price: price,
      priceDescription: priceDescription,
      priceDescriptionHebrew: priceDescriptionHebrew,
      priceIncludes: priceIncludes,
      priceIncludesHebrew: priceIncludesHebrew,
      confirmPolicy: confirmPolicy,
      confirmPolicyHebrew: confirmPolicyHebrew,
      excluded: excluded,
      excludedHebrew: excludedHebrew,
      formFirstName: formFirstName,
      formLastName: formLastName,
      languageSelected: languageSelected,
      preOverNight: overnightPreNight,
      postOverNight: overnightPostNight,
      dataAddon,
      sgpName: name
    };

    const htmlString = `
    <div style="
    background-image: url('https://cdn-durvi9qm.files-simplefileupload.com/static/blobs/proxy/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBNU1lQkE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--8514ecbbe703fe4dbd17399ee897e478f85245a7/templateBgImg1.png'),
                      url('https://cdn-durvi9qm.files-simplefileupload.com/static/blobs/proxy/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBNVFlQkE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--ed4528966c24c178d990ad33c498ea4f653bee81/templateBgImg2.png');
    background-position: right 25%, left 65%;
    background-size: 40%;
    background-repeat: no-repeat;
    background-color: #F0F0F0;
">
    <div style="width: 100%;">
        <div style="width: 90%; margin: 0 auto;">
            <div style="
                background: #FFF;
                padding: 1.25vw 1.875vw;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
            ">
                <div>
                    <img src="https://cdn-durvi9qm.files-simplefileupload.com/static/blobs/proxy/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBMGtZQkE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--58669e5c3cd88729a91faec7e0ef640212f6abd6/Indie_circle_logo.02875352198da00a8026.png" style="width: 80px; height: 80px;">
                </div>
                <div style="
                    text-align: left;
                    margin-top: 0.625vw;
                    margin-right: 0.625vw;
                    font-size: 13px;
                    font-weight: 400;
                    color: #000;
                    width: 100%;
                    margin-left: 60px;
                ">
                </div>
            </div>

            <div style="padding: 2.5vw 1.875vw; background: #FFF;">
                ${sharedData && !sharedData.languageSelected?.toLowerCase().includes("hebrew") ?
        `<div style="
                        font-style: normal;
                        font-weight: 600;
                        font-size: 20px;
                        text-align: left;
                        color: rgb(0, 20, 26);
                    ">
                        Hi ${sharedData && sharedData.formFirstName ? sharedData.formFirstName : ''}&nbsp;${sharedData && sharedData.formLastName ? sharedData.formLastName : ''}
                    </div>` :
        `<div style="
                        font-style: normal;
                        font-weight: 600;
                        font-size: 20px;
                        text-align: right;
                        color: rgb(0, 20, 26);
                        direction: rtl;
                    ">
                        שלום ${sharedData && sharedData.formFirstName ? sharedData.formFirstName : ''} ${sharedData && sharedData.formLastName ? sharedData.formLastName : ''}
                    </div>`
      }

                ${sharedData && !sharedData.languageSelected?.toLowerCase().includes("hebrew") ?
        `<div style="
                        font-style: normal;
                        font-size: 20px;
                        text-align: left;
                        color: rgb(0, 20, 26);
                        margin-top: 5px;
                    ">
                        This is your personalized trip. ${sharedData && sharedData.trip && sharedData.trip.name ? sharedData.trip.name : ""}. Your trip includes:
                    </div>` :
        `<div style="
                  font-style: normal;
                  font-size: 20px;
                  text-align: right;
                  color: rgb(0, 20, 26);
                  margin-top: 5px;
                  direction: rtl;
              ">
                  זהו הטיול המותאם אישית שלך. ${sharedData && sharedData.trip && sharedData.trip.name ? sharedData.trip.name : ""}. הטיול שלך כולל:
              </div>
              `
      }
            </div>

            ${sharedData && !sharedData.languageSelected?.toLowerCase().includes("hebrew") ?
        `
                 <div style="background: #fff; display: flex; padding-top: 10px;">
                           <div style="width: 42%; height: 15.625vw; background: #fff;">
                               <img style="width: 100%; height: 100%; object-fit: cover;" src=${sharedData && sharedData.trip && sharedData.trip.image ? sharedData.trip.image : ""} alt="">
                           </div>
                           <div style="width: 2%; height: 15.625vw"></div>
                           <div style="width: 27%; height: 15.625vw; background: #fff;">
                               <img style="width: 100%; height: 100%; object-fit: cover;" src="https://cdn-durvi9qm.files-simplefileupload.com/static/blobs/proxy/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBNGtlQkE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--11d6c04ee074298885936cc529073ef8eac95ae5/AppGuideSgpt.png" alt="">
                           </div>
                           <div style="width: 2%; height: 15.625vw"></div>
                           <div style="width: 27%; height: 15.625vw; background: #ffffff;">
                               <img style="width: 100%; height: 100%; object-fit: cover;" src="https://cdn-durvi9qm.files-simplefileupload.com/static/blobs/proxy/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBNGdlQkE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--0313c10f7418135ee872f9e6629abf237a06756d/SouthIndiaBiniKrishnadas.png" alt="">
                           </div>
                 </div>
                 <div style="
                           background: #f0f0f0;
                           display: flex;
                           width: 100%;
                           margin: 0 auto;
                           background: #F0F0F0;
                       ">
                           <div style="width: 41%; min-height: 150px; background: #fff; padding: 1.25vw;">
                               <div>
                                   <img style="width: 29px; height: 28px; object-fit: cover;" src="https://cdn-durvi9qm.files-simplefileupload.com/static/blobs/proxy/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBN2tlQkE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--f113f5258ce3f9e219123deed3ee491acc0bec72/Group1048.png" alt="">
                                   <div style="
                                           font-size: 14px;
                                           font-weight: 600;
                                           margin-bottom: 5px;
                                           margin-top: 10px;
                                       ">
                                           ${sharedData && sharedData.trip && sharedData.trip.days && sharedData.trip.days.length > 0 ? sharedData.trip.days.length : "0"}-days Personalized Itinerary
                                       </div>
                                       <div style="font-size: 14px; font-weight: 400;">
                                           Once you planned your trip, your personalized itinerary is ready.
                                       </div>
                               </div>
                           </div>
           
                           <div style="width: 2%; height: 150px"></div>
           
                           <div style="width: 25%; min-height: 150px; background: #fff; padding: 1.25vw;">
                               <div>
                                   <img style="width: 29px; height: 28px; object-fit: cover;" src="https://cdn-durvi9qm.files-simplefileupload.com/static/blobs/proxy/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBN2tlQkE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--f113f5258ce3f9e219123deed3ee491acc0bec72/Group1048.png" alt="">
                                   <div style="
                                           font-size: 14px;
                                           font-weight: 600;
                                           margin-bottom: 5px;
                                           margin-top: 10px;
                                       ">
                                           Indie App Guide
                                       </div>
                                       <div style="
                                           font-size: 14px;
                                           font-weight: 400;
                                       ">
                                           Navigate your entire Itinerary on the go and receive relevant tips along your route.
                                       </div>
                               </div>
                           </div>
           
                           <div style="width: 2%; height: 150px"></div>
           
                           <div style="width: 25%; min-height: 150px; background: #ffffff; padding: 1.25vw;">
                               <div>
                                   <img style="width: 29px; height: 28px; object-fit: cover;" src="https://cdn-durvi9qm.files-simplefileupload.com/static/blobs/proxy/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBN2tlQkE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--f113f5258ce3f9e219123deed3ee491acc0bec72/Group1048.png" alt="">
                                       <div style="
                                           font-size: 14px;
                                           font-weight: 600;
                                           margin-bottom: 5px;
                                           margin-top: 10px;
                                       ">
                                           Local Hero on the Ground for You
                                       </div>
                                       <div style="
                                           font-size: 14px;
                                           font-weight: 400;
                                       ">
                                           Will be there for you advising, arranging, helping, suggesting.
                                       </div>
                               </div>
                           </div>
                       </div>
           
            `
        :
        `
             <div style="background: #fff; display: flex; padding-top: 10px;">
               <div style="width: 27%; height: 15.625vw; background: #ffffff;">
                   <img style="width: 100%; height: 100%; object-fit: cover;" src="https://cdn-durvi9qm.files-simplefileupload.com/static/blobs/proxy/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBNGdlQkE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--0313c10f7418135ee872f9e6629abf237a06756d/SouthIndiaBiniKrishnadas.png" alt="">
               </div>
               <div style="width: 2%; height: 15.625vw"></div>
               <div style="width: 27%; height: 15.625vw; background: #fff;">
                   <img style="width: 100%; height: 100%; object-fit: cover;" src="https://cdn-durvi9qm.files-simplefileupload.com/static/blobs/proxy/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBNGtlQkE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--11d6c04ee074298885936cc529073ef8eac95ae5/AppGuideSgpt.png" alt="">
               </div>
               <div style="width: 2%; height: 15.625vw"></div>
               <div style="width: 42%; height: 15.625vw; background: #fff;">
                   <img style="width: 100%; height: 100%; object-fit: cover;" src=${sharedData && sharedData.trip && sharedData.trip.image ? sharedData.trip.image : ""} alt="">
               </div>
           </div>
           
           <div style="
                           background: #f0f0f0;
                           display: flex;
                           width: 100%;
                           margin: 0 auto;
                           background: #F0F0F0;
                       ">
                           <div style="width: 25%; min-height: 150px; background: #fff; padding: 1.25vw;">
                               <div>
                                   <img style="width: 29px; height: 28px; object-fit: cover;" src="https://cdn-durvi9qm.files-simplefileupload.com/static/blobs/proxy/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBN2tlQkE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--f113f5258ce3f9e219123deed3ee491acc0bec72/Group1048.png" alt="">
                                       <div style="
                                           font-size: 14px;
                                           font-weight: 600;
                                           margin-bottom: 5px;
                                           margin-top: 10px;
                                           direction: rtl;
                                       ">
                                           ${sharedData && sharedData.trip && sharedData.trip.days && sharedData.trip.days.length > 0 ? sharedData.trip.days.length : "0"}-יום תוכנית אישית
                                       </div>
                                       <div style="font-size: 14px; font-weight: 400; direction: rtl;">
                                           כאשר אתה מתכנן את הטיול שלך, התוכנית האישית שלך מוכנה.
                                       </div>
                               </div>
                           </div>
           
           
                           <div style="width: 2%; height: 150px"></div>
           
           
                           <div style="width: 25%; min-height: 150px; background: #fff; padding: 1.25vw;">
                               <div>
                                   <img style="width: 29px; height: 28px; object-fit: cover;" src="https://cdn-durvi9qm.files-simplefileupload.com/static/blobs/proxy/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBN2tlQkE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--f113f5258ce3f9e219123deed3ee491acc0bec72/Group1048.png" alt=""> 
                                       <div style="
                                           font-size: 14px;
                                           font-weight: 600;
                                           margin-bottom: 5px;
                                           margin-top: 10px;
                                           direction: rtl;
                                       ">
                                           מדריך לאפליקציה חכמה
                                       </div>
                                       <div style="
                                           font-size: 14px;
                                           font-weight: 400;
                                           direction: rtl;
                                       ">
                                           נווט בכל המסלול שלך בדרך וקבל טיפים רלוונטיים לאורך הדרך שלך.
                                       </div>
                               </div>
                           </div>
           
                           <div style="width: 2%; height: 150px"></div>
           
           
                           <div style="width: 41%; min-height: 150px; background: #ffffff; padding: 1.25vw;">
                               <div>
                                   <img style="width: 29px; height: 28px; object-fit: cover;" src="https://cdn-durvi9qm.files-simplefileupload.com/static/blobs/proxy/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBN2tlQkE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--f113f5258ce3f9e219123deed3ee491acc0bec72/Group1048.png" alt="">
                                       <div style="
                                           font-size: 14px;
                                           font-weight: 600;
                                           margin-bottom: 5px;
                                           margin-top: 10px;
                                           direction: rtl;
                                       ">
                                           ליווי טלפוני צמוד של מדריך שטח ביעד ומהארץ
                                       </div>
                               </div>
                           </div>
                       </div>
             `}
                                       
            ${sharedData && sharedData.trip && sharedData.trip.days && sharedData.trip.days.length > 0 && sharedData.trip.days.map(
          (item, index) => `
                        <div key=${index} style="padding-top: 30px;">
                            ${sharedData && !sharedData.languageSelected?.toLowerCase().includes("hebrew") ?
              `<div style="background-color: #A7C8EC; padding: 10px 3vw; font-weight: 600; font-size: 20px; color: rgb(255, 255, 255);">Day ${index + 1}</div>` :
              `<div style="background-color: #A7C8EC; padding: 10px 3vw; font-weight: 600; font-size: 20px; color: rgb(255, 255, 255); direction: rtl;">יום ${index + 1}</div>`
            }
                            <div style="width: 100%; background: white; padding-top: 20px;">
                                <div style="margin-top: 0px; margin-bottom: 10px; font-size: 17px; padding-left: 3vw; font-weight: 700; ${sharedData && sharedData.languageSelected?.toLowerCase().includes("hebrew") ? 'text-align: right; direction: rtl; padding-right: 3vw;' : 'padding-left: 3vw;'}">${item.name}</div>
                                <p style="margin-top: 0px; margin-bottom: 10px; font-size: 17px; padding-left: 3vw; padding-right: 1.25vw; font-weight: 400; ${sharedData && sharedData.languageSelected?.toLowerCase().includes("hebrew") ? 'text-align: right; direction: rtl;' : ''}">${item.description}</p>
                                ${item.poi_set && item.poi_set.length > 0 ?
              `<ul style="margin-top: 0px; margin-bottom: 10px; font-size: 17px; list-style-type: inherit; font-weight: 400; ${sharedData && sharedData.languageSelected?.toLowerCase().includes("hebrew") ? 'padding-left: 9vw; padding-right: 1.25vw; text-align: right; direction: rtl;' : 'padding-left: 1.25vw; padding-right: 9vw;'}">
                                        ${item.poi_set.map((poiItem, index) => `<li key=${index} style="margin-bottom: 8px; ${sharedData && sharedData.languageSelected?.toLowerCase().includes("hebrew") ? 'text-align: right; direction: rtl; margin-right: 1rem;' : ''}">${poiItem && poiItem.name ? poiItem.name : ""}</li>`).join("")}
                                    </ul>` : ''
            }
                                ${item && item.hotels && item.hotels.id ?
              sharedData && !sharedData.languageSelected?.toLowerCase().includes("hebrew") ?
                `<div>
                                        <div style="margin-top: 0px; margin-bottom: 10px; font-size: 17px; padding-left: 3vw; font-weight:700;">Overnight</div>
                                        <div style="margin-top: 0px; margin-bottom: 10px; font-size: 17px; padding-left: 3vw; font-weight:400;">${item.hotels.overnight_city}, <a href="${item.hotels.site_link}" target="_blank">${item.hotels.hotel_name}</a></div>
                                    </div>` :
                `<div>
                                        <div style="margin-top: 0px; margin-bottom: 10px; font-size: 17px; padding-right: 3vw; font-weight:700; text-align: right; direction: rtl;">לינה במלון</div>
                                        <div style="margin-top: 0px; margin-bottom: 10px; font-size: 17px; padding-right: 3vw; font-weight:400; text-align: right; direction: rtl;">${item.hotels.overnight_city}, <a href="${item.hotels.site_link}" target="_blank">${item.hotels.hotel_name}</a></div>
                                    </div>` : ''
            }
                            </div>
                        </div>
                    `
        ).join("")
      }
        </div>
    </div>
</div>

        `;
    try {
      const data = {
        subject: "iNDiE 4x4",
        email: formEmail,
        html: htmlString,
        hotelsData,
        ...sharedData
      };

      const jsonData = JSON.stringify(data);
      const jsonBlob = new Blob([jsonData], { type: 'application/json' });
      const formData = new FormData();
      formData.append("file", jsonBlob, "data.json");
      const response = await post(BASE_URL + "/self-guided-packages/build-pdf", formData);
      if (response.status == 200) {
        notify("Email sent successfully");
      }
    } catch (ex) { }
  }

  const handleAddAddon = () => {
    setAddons([
      ...addons,
      { name: "", checked: false, language: "", languageId: "" },
    ]);
  };

  const handleRemoveAddon = (index) => {
    const newAddons = [...addons];
    newAddons.splice(index, 1);
    setAddons(newAddons);
  };
  const handleAddUser = () => {
    setUsers([
      ...users,
      { firstName: "", lastName: false, email: "" },
    ]);
  };

  const handleRemoveUser = (index) => {
    const newUser = [...users];
    newUser.splice(index, 1);
    setUsers(newUser);
  };

  const handleAddonNameChange = (index, newName) => {
    const newAddons = [...addons];
    newAddons[index].name = newName;
    setAddons(newAddons);
  };
  const handleAddonTitleChange = (title) => {
    setAddonTitle(title);
  };

  const handleAddonCheckedChange = (index, checked) => {
    const newAddons = [...addons];
    newAddons[index].checked = checked;
    setAddons(newAddons);
  };

  const handleAddonLanguageChange = (index, languageId) => {
    const newAddons = [...addons];
    newAddons[index].languageId = languageId;
    const language = languageData.find((lang) => lang.id === languageId);
    if (language) {
      newAddons[index].language = language.name;
    }
    setAddons(newAddons);
  };

  const handleLanguageChange = (event) => {
    const selectedLanguageId = event.target.value;
    setSelectedLanguage(selectedLanguageId);
  };

  const handleContentChange = (event) => {
    setContentEmail(event.target.value);
  };

  const handleFirstNameUser = (index, newName) => {
    const newUser = [...users];
    newUser[index].firstName = newName;
    setUsers(newUser);
  };

  const handleLastNameUser = (index, newName) => {
    const newUser = [...users];
    newUser[index].lastName = newName;
    setUsers(newUser);
  };

  const handleEmailUser = (index, newEmail) => {
    const newUser = [...users];
    newUser[index].email = newEmail;
    setUsers(newUser);
  };

  function validateUsers() {
    let hayError = false;
    users.forEach(user => {
      if (!user.firstName || !user.lastName || !user.email) {
        let mensajeError = `User invitation with email ${user.email} has the following errors:\n`;
        if (!user.firstName) {
          mensajeError += "- The first name is missing for invitation\n";
        }
        if (!user.lastName) {
          mensajeError += "- The last name is missing for invitation\n";
        }
        if (!user.email) {
          mensajeError += "- Missing e-mail for invitation\n";
        }
        alert(mensajeError);
        hayError = true;
      }
    });
    return !hayError;
  }

  const handleSendInvitations = async () => {
    setLoading(true);
    try {
      if (validateUsers()) {
        const data = [];
        const userIds = [];
        const userSendInvitations = [];
        await Promise.all(users.map(async (user) => {
          const userExist = await get(BASE_URL + "/users/by-email/" + user.email);
          if (userExist && userExist.id) {
            const userData = {
              firstName: userExist.firstName,
              lastName: userExist.lastName,
              email: userExist.email,
              password: userExist.password
            };
            userSendInvitations.push(userExist.email)
            userIds.push(userExist.id);
            // data.push(userData); - do not send email for existing user...
          } else {
            const password = `${user.firstName}999!!!`;
            const createUserData = {
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              password,
            };
            if (trip && trip.id) {
              createUserData.active_trip_id = trip.id;
            }
            if (selfGuidedId && selfGuidedId.length > 0) {
              createUserData.self_guided_package_id = selfGuidedId;
            }
            const userDataNoExist = {
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              password
            };
            const newUser = await post(BASE_URL + "/users", createUserData);
            data.push(userDataNoExist);
            userSendInvitations.push(userDataNoExist.email);
            userIds.push(newUser.data.id);
          }
        }));
        setUsersInvitationIds(userIds);
        await Promise.all(data.map(async (user) => {
          const sendInvite = {
            "name": user.firstName,
            "email": user.email,
            "password": user.password
          };
          await post(BASE_URL + "/contact/send-email-invitation", sendInvite);
        }));
        notify(`Invitation sent to : ${userSendInvitations.join(', ')}`);
      }
    } catch (error) {
      alert("Hubo un error al enviar las invitaciones. Por favor, inténtalo de nuevo.");
    } finally {
      setLoading(false);
    }
  };

  async function tripSelectFun(term) {
    if (term) {
      let segments = term.split("-");
      let friendlyId = segments[segments.length - 1].trim();
      if (friendlyId) {
        const url = BASE_URL + `/trips/friendlyId/${friendlyId}`;
        const item = await get(url);
        setImageName(item.image);

        // Create a map from daysSort to index for quick lookup
        const sortOrderMap = new Map(item.days_sort.map((id, index) => [id, index]));
        // Sort the days array according to the sortOrderMap
        item.days.sort((a, b) => sortOrderMap.get(a.id) - sortOrderMap.get(b.id));

        setTrip(item);
        setShowTripSelect(false);
        setPriceIncludes(
          `<ul style="list-style-type: disc; padding-inline-start: 40px;">
                <li><span style="font-size: 18px;"><span style="font-size: 16px;">&nbsp;${item.days.length > 1 ? item.days.length - 1 : item.days.length
          } nights at good 3 * - 4 * hotels including breakfast</span ></span ></li >
                <li><span style="font-size: 16px;">&nbsp;Car model Hyundai Tucson or similar for ${item.days.length
          } days<br></br>(A local guide or driver is optional with an additional payment)</span></li>
                <li><span style="font-size: 16px;">&nbsp;Local Hero pre-trip advice session</span></li>
                <li><span style="font-size: 16px;">&nbsp;Indie4X4 App Guide premium package includes:</span>
                    <ul style="list-style-type: circle;padding-inline-start: 40px;padding-top: 10px">
                        <li><span style="font-size: 16px;">&nbsp; &nbsp;Navigation system with Your day-by-day personal itinerary</span></li>
                        <li><span style="font-size: 16px;">&nbsp; &nbsp;Points of interest</span></li>
                        <li><span style="font-size: 16px;">&nbsp; &nbsp;"Local Hero" tips based on location. Audio and Visual</span></li>
                        <li><span style="font-size: 16px;">&nbsp; &nbsp;Hiking trails nearby, curated by location, difficulty, distance</span></li>
                        <li>&nbsp; &nbsp;Local hero human support during your tour (9:00-18:00 destination time)<br></li>
                        <li><span style="font-size: 16px;">&nbsp; &nbsp;Offline navigation, anywhere, anytime</span></li>
                    </ul>
                </li>
            </ul>`
        );
      }
    }
  }

  function splitItem(item) {
    const array = item.split("/");
    const lastElement = array.pop();
    return lastElement;
  }

  async function handleNameChange(event) {
    const value = event.target.value;
    setName(value);
  }

  async function handleTripChange(event) {
    const value = event.target.value;
    setSearchTerm(value);

    if (value === '') {
      const allTrips = await xhrRequestFilterData("");
      if (allTrips) {
        const options = allTrips.map(trip => `${trip.name}-${trip.friendly_id}`);
        setTripsOptions(options);
      }
      return;
    }

    const data = await xhrRequestFilterData(value);
    if (data) {
      const options = data.map(trip => `${trip.name}-${trip.friendly_id}`);
      setTripsOptions(options);
    }
  }

  function xhrRequestFilterData(termData) {
    return new Promise(async (resolve, reject) => {
      try {
        let url;
        if (!isNaN(termData)) {
          url = BASE_URL + `/trips/findTrips?id=${termData}`;
        } else {
          url = BASE_URL + `/trips/findTrips?name=${termData.toLocaleUpperCase()}`;
        }
        const data = await get(url);
        resolve(data);
      } catch (error) {
        console.error('Error fetching trip data:', error);
        reject(error);
      }
    });
  }

  function handleChangeOvernight(event, isPre) {
    if (event && event !== undefined) {
      if (isPre) {
        let hotelId = event.data.friendly_id ? event.data.friendly_id : '';
        let hotelName = event.data.hotel_name ? event.data.hotel_name : '';
        let hotelCountry = (event.data.country && event.data.country.name) ? event.data.country.name : '';
        let hotelCity = event.data.overnight_city ? event.data.overnight_city : '';
        let label = `ID: ${hotelId}`;
        if (hotelName) label += `, ${hotelName}`;
        if (hotelCountry) label += `, ${hotelCountry}`;
        if (hotelCity) label += `, ${hotelCity}`;
        let jsonPre = {
          value: event.data.hotel_name,
          label,
          id: event.data.id,
          data: event.data,
        };
        setSelectedOptionPre(jsonPre);
        setOvernightPreNight(event.data.overnight_city);
        setOvernightPreNightId(event.data.id);
        setOvernightPreHotelSiteLink(event.data.site_link);
      } else {
        let hotelId = event.data.friendly_id ? event.data.friendly_id : '';
        let hotelName = event.data.hotel_name ? event.data.hotel_name : '';
        let hotelCountry = (event.data.country && event.data.country.name) ? event.data.country.name : '';
        let hotelCity = event.data.overnight_city ? event.data.overnight_city : '';
        let label = `ID: ${hotelId}`;
        if (hotelName) label += `, ${hotelName}`;
        if (hotelCountry) label += `, ${hotelCountry}`;
        if (hotelCity) label += `, ${hotelCity}`;
        let jsonPost = {
          value: event.data.hotel_name,
          label,
          id: event.data.id,
          data: event.data,
        };
        setSelectedOptionPost(jsonPost);
        setOvernightPostNight(event.data.overnight_city);
        setOvernightPostNightId(event.data.id);
        setOvernightPostHotelSiteLink(event.data.site_link);
      }
    } else {
      console.error("Invalid event or value:", event);
    }
  }

  return (
    <div className="m-2 ml-10 mr-10 mt-5 p-2 p-5 bg-white rounded-3xl">
      <div className="flex justify-between items-center h-20">
        <Header
          category=""
          title={
            fromStatus == "create"
              ? "Create Self Guided Package"
              : `Edit Self Guided Package ${friendlyId}`
          }
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 20,
          }}
          className="mb-10 mr-20"
        >
          {personalized ? (
            <img
              src={BlueCheck}
              style={{ height: 25, width: 25, cursor: "pointer" }}
              onClick={() => {
                setPersonalized(!personalized);
              }}
            />
          ) : (
            <div
              style={{
                borderRadius: 4,
                height: 25,
                width: 25,
                border: "2px solid #2D96F3",
                cursor: "pointer",
              }}
              onClick={() => {
                setPersonalized(!personalized);
              }}
            ></div>
          )}
          <div style={{ fontSize: 22, fontWeight: "bold" }}>Personalized</div>
        </div>
      </div>
      <div className="countryFormMainDiv p-10">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: 100,
          }}
        >
          <div style={{ width: "30%" }}>
            <div>
              <span style={{ fontSize: 20, color: "#000" }}>Select Trip</span>
              <Autocomplete
                freeSolo
                options={tripsOptions}
                value={searchTerm}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Trip Name"
                    onClick={(event) => {
                      if (!searchTerm) {
                        handleTripChange({ target: { value: '' } });
                      } else {
                        handleTripChange({ target: { value: searchTerm } });
                      }
                    }}
                    onChange={(event) => handleTripChange(event)}
                    value={searchTerm}
                    sx={{ width: 200 }}
                  />
                )}
                onChange={(event, value) => tripSelectFun(value)}
              />

            </div>
            <div style={{ marginTop: 20 }}>
              <span style={{ fontSize: 20, color: "#000" }}>Select Name</span><br />
              <TextField
                label="Sgp Name"
                onChange={(event) => handleNameChange(event)}
                value={name}
                sx={{ width: 400 }}
              />
            </div>
            <div
              style={{
                width: "60%",
                marginTop: 25,
              }}
            >
              <span style={{ fontSize: 20, color: "#000" }}>Currency</span>

              <div
                className="InputBoxCss"
                style={{
                  width: 200,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                  padding: "5px 12px",
                  marginTop: 10,
                }}
                onClick={() => {
                  setShowCurrencySelect(!showCurrencySelect);
                  setErrorMsg("");
                }}
              >
                {currency && currency.id ? (
                  <span style={{ color: "#000" }}>
                    {currency.name}&nbsp;({currency.symbol})
                  </span>
                ) : (
                  <span style={{ color: "gray" }}>Select Currency</span>
                )}
                <img
                  src={DropDownArrow}
                  style={{
                    width: 15,
                    height: 15,
                    transform: showCurrencySelect
                      ? "rotate(270deg)"
                      : "rotate(90deg)",
                  }}
                />
              </div>

              {showCurrencySelect && (
                <ClickAwayListener
                  onClickAway={() => {
                    setShowCurrencySelect(false);
                  }}
                >
                  <div className="dropDownDivCurrencies">
                    {currencyData &&
                      currencyData.length > 0 &&
                      currencyData.map((item, index) => (
                        <div
                          className="countryDropDownItemDiv"
                          onClick={() => {
                            setCurrency(item);
                            setShowCurrencySelect(false);
                          }}
                        >
                          {item.name}&nbsp;({item.symbol})
                        </div>
                      ))}
                  </div>
                </ClickAwayListener>
              )}
            </div>
            <div
              style={{
                width: "60%",
                marginTop: 25,
              }}
            >
              <span style={{ fontSize: 20, color: "#000" }}>Offer Language</span><br />
              <div
                className="InputBoxCss"
                style={{
                  width: 200,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                  padding: "5px 12px",
                  marginTop: 10,
                }}
                onClick={() => {
                  setShowLanguageSelect(!showCurrencySelect);
                  setErrorMsg("");
                }}
              >
                {languageSelected ? (
                  <span style={{ color: "#000" }}>
                    {languageSelected}
                  </span>
                ) : (
                  <span style={{ color: "gray" }}>Select Language</span>
                )}
                <img
                  src={DropDownArrow}
                  style={{
                    width: 15,
                    height: 15,
                    transform: showLanguageSelect
                      ? "rotate(270deg)"
                      : "rotate(90deg)",
                  }}
                />
              </div>

              {showLanguageSelect && (
                <ClickAwayListener
                  onClickAway={() => {
                    setShowCurrencySelect(false);
                  }}
                >
                  <div className="dropDownDivCurrencies">
                    {langEngHebrew &&
                      langEngHebrew.length > 0 &&
                      langEngHebrew.map((item, index) => (
                        <div
                          className="countryDropDownItemDiv"
                          onClick={() => {
                            setLanguageSelected(item.name?.toLowerCase());
                            setShowLanguageSelect(false);
                          }}
                        >
                          {item.name}
                        </div>
                      ))}
                  </div>
                </ClickAwayListener>
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
              gap: 10,
              width: "40%",
            }}
          >
            <span style={{ fontSize: 20, color: "#000" }}>Image</span>
            {imageName && imageName != "" ? (
              <>
                <img
                  src={imageName}
                  style={{ width: 250, height: 150, borderRadius: 10 }}
                  alt=""
                />
                <div
                  className="changeImg"
                  onClick={() => {
                    setShowImagePopUp(true);
                  }}
                >
                  Change Image
                </div>
              </>
            ) : (
              <SimpleFileUpload
                apiKey={SimpleFileUploadAPIKey}
                onSuccess={handleUpload}
                width="350"
              />
            )}
          </div>
        </div>

        <div style={{ marginTop: 30, marginBottom: 30 }}>
          <div style={{ fontSize: 18, fontWeight: 800, marginBottom: 15 }}>
            PRICING
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 100,
              marginTop: 25,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                gap: 10,
                width: "50%",
              }}
            >
              <span style={{ fontSize: 20, color: "#000" }}>Price</span>
              <input
                type="number"
                className="InputBoxCss"
                style={{ width: "30%" }}
                value={price}
                onChange={(e) => {
                  setPrice(e.target.value);
                }}
                onFocus={() => {
                  setErrorMsg("");
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                gap: 10,
                width: "50%",
              }}
            >
              <span style={{ fontSize: 20, color: "#000" }}>
                Number Of Travellers
              </span>
              <input
                type="number"
                className="InputBoxCss"
                style={{ width: 50 }}
                value={travellersNumber}
                onChange={(e) => {
                  setTravellersNumber(e.target.value);
                }}
                onFocus={() => {
                  setErrorMsg("");
                }}
              />
            </div>
          </div>
        </div>

        <div style={{ marginTop: 0 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
              gap: 10,
              width: "50%",
            }}
          >
            <span style={{ fontSize: 20, color: "#000" }}>Start Date</span>
            <div className="InputBoxCss datePickerCss" style={{ width: 250 }}>
              <DatePickerComponent
                value={selectedDate}
                format='dd-MMM-yyyy'
                change={handleDateChange}
              />
            </div>
            <span style={{ fontSize: 20, color: "#000" }}>End Date</span>
            <div className="InputBoxCss datePickerCss" style={{ width: 250 }}>
              <DatePickerComponent
                value={selectedEndDate}
                format='dd-MMM-yyyy'
                change={handleDateEndChange}
              />
            </div>
          </div>
        </div>

        <div style={{ marginTop: 30 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
              gap: 10,
              width: "100%",
            }}
          >
            <span style={{ fontSize: 20, color: "#000" }}>
              Price Description
            </span>
            {languageSelected?.toLowerCase().includes("hebrew") ?
              <div className="datePickerCss" style={{ width: "100%", direction: "rtl" }}>
                <RichTextEditorComponent
                  value={priceDescriptionHebrew}
                  change={handlePriceDesChangeHebrew}
                >
                  <Inject
                    services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]}
                  />
                </RichTextEditorComponent>
              </div> :
              <div className="datePickerCss" style={{ width: "100%", }}>
                <RichTextEditorComponent
                  value={priceDescription}
                  change={handlePriceDesChange}
                >
                  <Inject
                    services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]}
                  />
                </RichTextEditorComponent>
              </div>
            }
          </div>
        </div>

        <div style={{ marginTop: 30 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
              gap: 10,
              width: "100%",
            }}
          >
            <span style={{ fontSize: 20, color: "#000" }}>Price includes</span>
            {languageSelected?.toLowerCase().includes("hebrew") ?
              <div className="datePickerCss" style={{ width: "100%", direction: "rtl" }}>
                <RichTextEditorComponent
                  value={priceIncludesHebrew}
                  change={handlePriceIncludesChangeHebrew}
                >
                  <Inject
                    services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]}
                  />
                </RichTextEditorComponent>
              </div>
              :
              <div className="datePickerCss" style={{ width: "100%" }}>
                <RichTextEditorComponent
                  value={priceIncludes}
                  change={handlePriceIncludesChange}
                >
                  <Inject
                    services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]}
                  />
                </RichTextEditorComponent>
              </div>
            }
          </div>
        </div>

        <div style={{ marginTop: 30 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
              gap: 10,
              width: "100%",
            }}
          >
            <span style={{ fontSize: 20, color: "#000" }}>Excluded</span>
            {languageSelected?.toLowerCase().includes("hebrew") ?
              <div className="datePickerCss" style={{ width: "100%", direction: "rtl" }}>
                <RichTextEditorComponent
                  value={excludedHebrew}
                  change={handlePriceExcludedChangeHebrew}
                >
                  <Inject
                    services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]}
                  />
                </RichTextEditorComponent>
              </div>
              :
              <div className="datePickerCss" style={{ width: "100%" }}>
                <RichTextEditorComponent
                  value={excluded}
                  change={handlePriceExcludedChange}
                >
                  <Inject
                    services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]}
                  />
                </RichTextEditorComponent>
              </div>
            }
          </div>
        </div>

        {/* <div style={{ marginTop: 30 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
              gap: 10,
              width: "100%",
            }}
          >
            <span style={{ fontSize: 20, color: "#000" }}>
              Confirmation Policy
            </span>
            {languageSelected?.toLowerCase().includes("hebrew") ?
              <div className="datePickerCss" style={{ width: "100%", direction: "rtl" }}>
                <RichTextEditorComponent
                  value={confirmPolicyHebrew}
                  change={handleConfirmPolicyChangeHebrew}
                >
                  <Inject
                    services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]}
                  />
                </RichTextEditorComponent>
              </div>
              :
              <div className="datePickerCss" style={{ width: "100%" }}>
                <RichTextEditorComponent
                  value={confirmPolicy}
                  change={handleConfirmPolicyChange}
                >
                  <Inject
                    services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]}
                  />
                </RichTextEditorComponent>
              </div>

            }
          </div>
        </div> */}

        {trip && trip.days && trip.days.length > 0 && (
          <div style={{ marginTop: 20, marginBottom: 10 }}>
            <table className="selfGuidedTable">
              <tr className="selfGuidedTableTr">
                <th className="selfGuidedTableTd" style={{ width: "30%" }}>
                  Day
                </th>
                <th className="selfGuidedTableTd" style={{ width: "25%" }}>
                  Overnight
                </th>
                <th className="selfGuidedTableTd" style={{ width: "45%" }}>
                  Hotel Name
                </th>
              </tr>
              {trip.days.map((item, index) => (
                <tr className="selfGuidedTableTr">
                  <td className="selfGuidedTableTd" style={{ width: "30%" }}>
                    Day {index + 1}
                  </td>
                  <td className="selfGuidedTableTd" style={{ width: "25%" }}>
                    {item.overnight
                      ? item.overnight
                      : item.hotels
                        ? item.hotels.hotel_name
                        : ""}
                  </td>
                  <td className="selfGuidedTableTd" style={{ width: "45%" }}>
                    {index !== trip.days.length - 1 ? (
                      <Select
                        options={option}
                        isSearchable
                        placeholder=""
                        value={hotelsData[index]}
                        onChange={(e) => {
                          handleChangeHotel(e, item, index, trip);
                        }}
                      />
                    ) : (
                      "End of the trip"
                    )}
                  </td>
                </tr>
              ))}
            </table>
            <div style={{ marginTop: 30 }}>
              <table className="selfGuidedTable">
                <thead>
                  <tr className="selfGuidedTableTr">
                    <th className="selfGuidedTableTd">Category</th>
                    <th className="selfGuidedTableTd">Content</th>
                    <th className="selfGuidedTableTd">Hotel Name</th>{" "}
                    {/* Nueva columna para Hotel Name */}
                  </tr>
                </thead>
                <tbody>
                  <tr className="selfGuidedTableTr">
                    <td className="selfGuidedTableTd">Pre-Overnight</td>
                    <td className="selfGuidedTableTd">
                      <input
                        type="text"
                        className="InputBoxCss"
                        style={{ width: "100%" }}
                        placeholder="Enter Pre-Overnight"
                        value={overnightPreNight}
                        onChange={(e) => setOvernightPreNight(e.target.value)}
                      />
                    </td>
                    <td className="selfGuidedTableTd">
                      {" "}
                      {/* Nueva columna para el select */}
                      <Select
                        options={option}
                        isSearchable
                        placeholder="Select Pre-Hotel"
                        value={selectedOptionPre}
                        onChange={(e) => {
                          handleChangeOvernight(e, true);
                        }}
                      />
                    </td>
                  </tr>
                  <tr className="selfGuidedTableTr">
                    <td className="selfGuidedTableTd">Post-Overnight</td>
                    <td className="selfGuidedTableTd">
                      <input
                        type="text"
                        className="InputBoxCss"
                        style={{ width: "100%" }}
                        placeholder="Enter for Post-Overnight"
                        value={overnightPostNight}
                        onChange={(e) => setOvernightPostNight(e.target.value)}
                      />
                    </td>
                    <td className="selfGuidedTableTd">
                      {" "}
                      {/* Nueva columna para el select */}
                      <Select
                        options={option}
                        isSearchable
                        value={selectedOptionPost}
                        placeholder="Select Post-Hotel"
                        onChange={(e) => {
                          handleChangeOvernight(e, false);
                        }}
                      />
                    </td>
                  </tr>
                  <tr className="selfGuidedTableTr">
                    <td className="selfGuidedTableTd">More Content</td>
                    <td className="selfGuidedTableTd" colSpan="2">
                      <textarea
                        className="InputBoxCss"
                        style={{ width: "100%", minHeight: "100px" }}
                        placeholder="Enter more content here"
                        value={contentEmail}
                        onChange={handleContentChange}
                      ></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}

        <div style={{ marginTop: 30, marginBottom: 30 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "column",
              gap: 10,
              width: "100%",
            }}
          >
            <span style={{ fontSize: 20, color: "#000" }}>Addons</span>
            <span style={{ fontSize: 15, color: "#000" }}>
              (only the checked ones are saved)
            </span>
            <h3>Addon Title</h3>
            <input
              className="InputBoxCss"
              style={{ width: 250, marginRight: "10px" }}
              type="text"
              value={addonTitle}
              onChange={(e) => handleAddonTitleChange(e.target.value)}
            />
            {addons.map((addon, index) => (
              <div
                key={index}
                style={{ display: "flex", alignItems: "center", gap: 20 }}
              >
                <input
                  type="checkbox"
                  style={{ transform: "scale(1.5)" }}
                  checked={addon.checked}
                  onChange={(e) =>
                    handleAddonCheckedChange(index, e.target.checked)
                  }
                />
                <input
                  className="InputBoxCss"
                  style={{ width: 250, marginRight: "10px" }}
                  type="text"
                  value={addon.name}
                  onChange={(e) => handleAddonNameChange(index, e.target.value)}
                />
                <select
                  className="InputBoxCss"
                  style={{ width: 250 }}
                  value={addon.languageId}
                  onChange={(e) =>
                    handleAddonLanguageChange(index, e.target.value)
                  }
                >
                  <option>Select Language</option>
                  {languageData.map((language) => (
                    <option key={language.id} value={language.id}>
                      {language.name}
                    </option>
                  ))}
                </select>
                {index === addons.length - 1 && (
                  <button style={{ fontSize: 30 }} onClick={handleAddAddon}>
                    +
                  </button>
                )}
                {index !== addons.length - 1 && (
                  <button
                    style={{ fontSize: 30 }}
                    onClick={() => handleRemoveAddon(index)}
                  >
                    -
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "column",
            gap: 20,
            width: "100%",
            marginTop: 40,
            marginBottom: 40,
          }}
        >
          <span style={{ fontSize: 20, color: "#000" }}>Vouchers (will appear in the mobile app)</span>
          {voucher &&
            voucher.length > 0 &&
            voucher.map((item, index) => (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 40,
                }}
              >
                <div className="voucherItem">
                  <b> {index + 1}. </b>{" "}
                  <a href={item} target="blank">
                    {" "}
                    {splitItem(item)}
                  </a>
                </div>

                <img
                  src={CrossIcon}
                  style={{ height: 10, width: 10, cursor: "pointer" }}
                  onClick={() => {
                    deleteVoucher(item, index);
                  }}
                />
              </div>
            ))}
          <SimpleFileUpload
            apiKey={SimpleFileUploadAPIKey}
            onSuccess={handleMultipleUpload}
            preview={false}
            multiple={true}
            maxFiles={5}
            removeLinks={true}
            buttonClass="voucherFileBtn"
            buttonText="Upload Files"
          />
        </div>

        <div
          style={{
            marginTop: 30,
            marginBottom: 20,
            padding: "20px 15px",
            border: "1px solid #0000001f",
            width: 500,
          }}
        >
          <div
            style={{
              fontSize: 18,
              fontWeight: 800,
              marginBottom: 15,
              borderBottom: "1px solid #0000001f",
              paddingBottom: 10,
            }}
          >
            Fill form to send email
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 100,
              marginTop: 25,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                gap: 10,
                width: "100%",
              }}
            >
              <span style={{ fontSize: 20, color: "#000" }}>First Name</span>
              <input
                type="text"
                className="InputBoxCss"
                style={{ width: "100%" }}
                value={formFirstName}
                onChange={(e) => {
                  setFormFirstName(e.target.value);
                }}
                onFocus={() => {
                  setErrorMsg("");
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 100,
              marginTop: 25,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                gap: 10,
                width: "100%",
              }}
            >
              <span style={{ fontSize: 20, color: "#000" }}>Last Name</span>
              <input
                type="text"
                className="InputBoxCss"
                style={{ width: "100%" }}
                value={formLastName}
                onChange={(e) => {
                  setFormLastName(e.target.value);
                }}
                onFocus={() => {
                  setErrorMsg("");
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 100,
              marginTop: 25,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                gap: 10,
                width: "100%",
              }}
            >
              <span style={{ fontSize: 20, color: "#000" }}>Email</span>
              <input
                type="email"
                className="InputBoxCss"
                style={{ width: "100%" }}
                value={formEmail}
                onChange={(e) => {
                  setFormEmail(e.target.value);
                }}
                onFocus={() => {
                  setErrorMsg("");
                }}
              />
            </div>
          </div>
        </div>
        {setInvitations && <div style={{ marginTop: 30, marginBottom: 30 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "column",
              gap: 10,
              width: "100%",
            }}
          >
            <span style={{ fontSize: 20, color: "#000" }}>Users Invitations</span>
            {users.map((addon, index) => (
              <div
                key={index}
                style={{ display: "flex", alignItems: "center", gap: 20 }}
              >
                <input
                  className="InputBoxCss"
                  style={{ width: 250, marginRight: "10px" }}
                  type="text"
                  placeholder="First Name"
                  value={users.firstName}
                  onChange={(e) => handleFirstNameUser(index, e.target.value)}
                />

                <input
                  className="InputBoxCss"
                  style={{ width: 250, marginRight: "10px" }}
                  placeholder="Last Name"
                  type="text"
                  value={users.lastName}
                  onChange={(e) => handleLastNameUser(index, e.target.value)}
                />

                <input
                  className="InputBoxCss"
                  style={{ width: 250, marginRight: "10px" }}
                  placeholder="Email"
                  type="text"
                  value={users.email}
                  onChange={(e) => handleEmailUser(index, e.target.value)}
                />

                {index === users.length - 1 && (
                  <button style={{ fontSize: 30 }} onClick={handleAddUser}>
                    +
                  </button>
                )}
                {index !== users.length - 1 && (
                  <button
                    style={{ fontSize: 30 }}
                    onClick={() => handleRemoveUser(index)}
                  >
                    -
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>}
        <div
          style={{
            width: 800,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 50,
            height: 60,
          }}
        >
          <div
            className="AddBtn"
            style={{ margin: 0 }}
            onClick={() => {
              viewTemplate();
            }}
          >
            View
          </div>
          <div
            className="AddBtn"
            style={{ margin: 0 }}
            onClick={() => {
              sendEmailTemplate();
            }}
          >
            Send Email
          </div>
          {setInvitations ?
            <div
              className="downloadBtn"
              style={{ margin: 0 }}
              onClick={() => {
                handleSendInvitations();
              }}
            >
              Send Invitations
            </div>
            :
            <div
              className="downloadBtn"
              style={{ margin: 0 }}
              onClick={() => {
                setSetInvitations(true);
              }}
            >
              Set Download Invitations
            </div>
          }
          {loading &&
            <div class="loader-container">
              <div class="loader"></div>
            </div>
          }
        </div>

        {errorMsg != "" && (
          <div
            style={{
              textAlign: "center",
              color: "red",
              fontSize: 16,
              marginTop: 30,
            }}
          >
            {errorMsg}
          </div>
        )}

        {fromStatus == "create" ? (
          <div
            className="AddBtn"
            onClick={() => {
              CreateSelfGuidedFun();
            }}
          >
            Add
          </div>
        ) : (
          <div
            className="AddBtn"
            style={{ width: "400px" }}
            onClick={() => {
              editSelfGuidedFun();
            }}
          >
            Update Self Guided Package {friendlyId}
          </div>
        )}
      </div>

      {showImagePopUp && (
        <div
          style={{
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: "rgba(0,0,0,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => {
            setShowImagePopUp(false);
          }}
        >
          <div
            style={{
              background: "#fff",
              borderRadius: 10,
              padding: "15px",
            }}
          >
            <SimpleFileUpload
              apiKey={SimpleFileUploadAPIKey}
              onSuccess={handleUpload}
              width="350"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateSelfGuided;
