import React, { useEffect, useState } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Toolbar,
  Sort,
  Filter,
} from "@syncfusion/ej2-react-grids";

import { userGrid } from "../data/dummy";
import { Header } from "../components";
import { BASE_URL, get } from "../Services/Calls";
import LeftArrow from "../data/leftArrow.png"
import RightArrow from "../data/rightArrow.png"
import BackWard from "../data/backWard.png"
import Forward from "../data/forward.png"

const Users = () => {
  const selectionsettings = { persistSelection: true };
  const toolbarOptions = ['Search'];
  const editing = { allowDeleting: true, allowEditing: true };

  const [userData, setUserData] = useState([])


  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const pageSize = 10; // Number of records per page

  // Function to handle page change
  const onPageChange = (page) => {
    setCurrentPage(page);
    getUsers(page)
  };


  useEffect(() => {
    getUsers(currentPage)
  }, [])


  const getUsers = async (pageValue) => {
    try{
    const response = await get(BASE_URL + `/users?page=${pageValue}&take=10`);
    if (response && response.data && response.data.length > 0) {
      setUserData(response.data)
      setTotalPages(Math.ceil(response.meta.itemCount / pageSize))
      setTotalItems(response.meta.itemCount)
    }
  }
  catch(ex){
    alert(ex)
  }
  };

  const numbers = Array.from({ length: totalPages }, (_, index) => index + 1);

  return (
    <div className="m-2 ml-10 mr-10 mt-5 p-2 p-5 bg-white rounded-3xl">
      <Header category="" title="Users" />
      {userData && userData.length > 0 &&
        <GridComponent
          dataSource={userData}
          enableHover={false}
          allowPaging={false}
          // allowFiltering
          // pageSettings={{ pageCount: 5,pageSize: 10 }}
          // selectionSettings={selectionsettings}
          toolbar={toolbarOptions}
          // editSettings={editing}
          allowSorting
        >
          <ColumnsDirective>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {userGrid.map((item, index) => (
              <ColumnDirective key={index} {...item} />
            ))}
          </ColumnsDirective>
          <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter]} />
        </GridComponent>
      }
     <div style={{textAlign: 'center', display: 'flex', justifyContent: 'space-between', alignItems: 'center',padding: '10px 10px 10px 27px',border: '1px solid #e0e0e0'}}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 5, fontSize: 13 }}>
        <div onClick={() => { if (currentPage > 1) {onPageChange(1)} }} style={{ marginRight: 21 }}>
            <img src={BackWard} style={{ width: 11, height: 11, opacity: currentPage > 1 ? 1 : 0.5, cursor: 'pointer' }} />
          </div>
          <div onClick={() => { if (currentPage > 1) { onPageChange(currentPage - 1) } }} style={{ marginRight: 14 }}>
            <img src={LeftArrow} style={{ width: 9, height: 9, opacity: currentPage > 1 ? 1 : 0.5, cursor: 'pointer' }} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 5, fontSize: 13 }}>
            {currentPage > 2 &&
              <div className="pagingNumber" onClick={() => {onPageChange(currentPage - 2)}} style={{fontWeight:'bold'}}>
                ...
              </div>
            }
            {numbers.map((number) => (
              <>
                {((number == currentPage || number == currentPage - 1 || number == currentPage + 1) || (currentPage == 1 && number == 3) || (currentPage == totalPages && number == totalPages - 2)) &&
                  <div key={number} className={currentPage == number ? "pagingNumberSelected" : "pagingNumber"} onClick={() => { onPageChange(number)}}>{number}</div>
                }
              </>
            ))}
            {currentPage + 1 < totalPages &&
              <div className="pagingNumber" onClick={() => {onPageChange(currentPage + 2) }} style={{fontWeight:'bold'}}>
                ...
              </div>
            }
          </div>
          <div onClick={() => { if (totalPages != currentPage) { onPageChange(currentPage + 1) } }} style={{ marginLeft: 13.5 }}>
            <img src={RightArrow} style={{ width: 10, height: 10, opacity: totalPages != currentPage ? 1 : 0.5, cursor: 'pointer' }} />
          </div>
          <div onClick={() => { if (totalPages != currentPage) { onPageChange(totalPages) } }} style={{ marginLeft: 19 }}>
            <img src={Forward} style={{ width: 12, height: 12, opacity: totalPages != currentPage ? 1 : 0.5, cursor: 'pointer' }} />
          </div>
        </div>
        <span style={{ margin: '0 6.5px', fontSize: 13 }}>{`${currentPage} of ${totalPages} Pages (${totalItems} items)`}</span>
      </div>

    </div>
  );
};

export default Users;
