import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SimpleFileUpload from 'react-simple-file-upload';
import { BASE_URL, get } from "../../Services/Calls";
import { Header } from "../../components";
import CheckIcon from '../../data/check.png';
import CrossIcon from '../../data/createAddIcon.png';
import DropDownArrow from '../../data/rightArrow.png';
import "../../pages/NewPages/Map/MapBox.css";
import './NewMain.css';
import { mapboxglAccessToken, notify, SimpleFileUploadAPIKey } from '../../Services/Utils';


mapboxgl.accessToken = mapboxglAccessToken;


const CreateWayPoints = ({ showAddWayPointPopUp, setShowAddWayPointPopUp, wayPointData, setWayPointData, wayPointItem, wayPointIndex, setWayPointItem, addMarkerInGPXMap }) => {

    const navigate = useNavigate()
    const location = useLocation();

    const [wayPointName, setWayPointName] = useState('');
    const [wayPointId, setWayPointId] = useState('');
    const [wayPointImageName, setWayPointImageName] = useState('');
    const [description, setDescription] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [showImagePopUp, setShowImagePopUp] = useState(false);
    const [wayPointLocation, setWayPointLocation] = useState('');
    const [poiData, setPoiData] = useState([])
    const [poiDataOptions, setPoiDataOptions] = useState([])
    const [countryData, setCountryData] = useState([]);
    const [showCountrySelect, setShowCountrySelect] = useState(false);
    const [showWayPointTypeSelect, setShowWayPointTypeSelect] = useState(false);
    const [showPoiSelect, setShowPoiSelect] = useState(false);
    const [country, setCountry] = useState({});
    const [isEditPage, setIsEditPage] = useState('add');
    const [wayPointTypeArray, setWayPointTypeArray] = useState([
        {
            id: 1,
            name: 'LOCAL HERO TIP',
            type: 1,
        },
        {
            id: 2,
            name: 'RIGHT',
            type: 2,
        },
        {
            id: 3,
            name: 'LEFT',
            type: 3,
        },
        {
            id: 4,
            name: 'Branching / fork to the right',
            type: 4,
        },
        {
            id: 5,
            name: 'Branching / fork to the left',
            type: 5,
        },
        {
            id: 6,
            name: 'Point of interest',
            type: 6,
        },
        {
            id: 7,
            name: 'Information Icon',
            type: 7,
        },
        {
            id: 8,
            name: 'Icon for swimming',
            type: 8,
        },
        {
            id: 9,
            name: 'Rafting / kayaking icon',
            type: 9,
        },
    ]);

    const [wayPointType, setWayPointType] = useState({});
    const [poiArray, setPoiArray] = useState([]);

    const [map, setMap] = useState(null);
    const [mapStyle, setMapStyle] = useState('mapbox://styles/mapbox/streets-v11');
    const [mapMode, setMapMode] = useState('1');

    const [waypointLat, setWaypointLat] = useState('');
    const [waypointLong, setWaypointLong] = useState('');

    const mapRef3 = useRef(null);
    const marker3Ref = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0);
        // Create a map instance
        mapRef3.current = new mapboxgl.Map({
            container: 'map3', // Specify the container ID
            style: mapStyle, // Specify the map style
            center: [32.00, 35], // Specify the starting center
            zoom: 5, // Specify the starting zoom
        });
        mapRef3.current.on('load', () => {
            setMap(mapRef3.current);
        });

        mapRef3.current.on('style.load', function () {
            mapRef3.current.on('click', function (e) {
                var coordinates = e.lngLat;
                new mapboxgl.Popup()
                if (marker3Ref.current != null) {
                    marker3Ref.current.remove()
                }
                marker3Ref.current = new mapboxgl.Marker()
                    .setLngLat(coordinates)
                    .addTo(mapRef3.current);
                //setWayPointLocation(coordinates.lat + "," + coordinates.lng);
                setWaypointLat(coordinates.lat);
                setWaypointLong(coordinates.lng);
            });
        });
        return () => mapRef3.current.remove();
    }, [mapStyle, mapMode]); // The empty dependency array ensures this useEffect runs only once

    useEffect(() => {
        if (wayPointItem && wayPointItem.name) {
            setIsEditPage('edit');
            setWayPointName(wayPointItem.name);
            if (wayPointItem.id) {
                setWayPointId(wayPointItem.id);
            }
            setDescription(wayPointItem.description);
            setWayPointImageName(wayPointItem.photo);
            setCountry(wayPointItem.country);
            for (let i = 0; i < wayPointTypeArray.length; i++) {
                if (wayPointItem.type == wayPointTypeArray[i].type) {
                    setWayPointType(wayPointTypeArray[i]);
                }
            }
            setWaypointLat(wayPointItem.location.latitude);
            setWaypointLong(wayPointItem.location.longitude);
            setWayPointLocation(wayPointItem.location.latitude + "," + wayPointItem.location.longitude)
            marker3Ref.current = new mapboxgl.Marker()
                .setLngLat([wayPointItem.location.longitude, wayPointItem.location.latitude])
                .addTo(mapRef3.current);
            mapRef3.current.flyTo({ center: [wayPointItem.location.longitude, wayPointItem.location.latitude], zoom: 6 });
        }
        if (wayPointItem && wayPointItem.name && wayPointItem.type === 6) {
            const dataPoi = [wayPointItem];
            setPoiArray(dataPoi);
        }
    }, []);

    useEffect(() => {
        (async () => {
            await getPoi();
            await getCountries();
        })();
    }, []);

    const isValidDate = date => {
        return date instanceof Date && !isNaN(date);
    };

    const getPoi = async () => {
        try {
            const response = await get(BASE_URL + '/pois');
            if (response && response.results && response.results.length > 0) {
                const sortedPois = response.results.sort((a, b) => {
                    const dateA = new Date(a.createdAt);
                    const dateB = new Date(b.createdAt);
                    if (isValidDate(dateA) && isValidDate(dateB)) {
                        return dateB - dateA
                    } else if (isValidDate(dateA)) {
                        return -1;
                    } else if (isValidDate(dateB)) {
                        return 1;
                    } else {
                        return 0;
                    }
                })
                setPoiData(sortedPois);
                setPoiData(sortedPois);
                setPoiDataOptions(sortedPois);
            }
        }
        catch (ex) {
            alert(ex)
        }
    }

    const getCountries = async () => {
        try {
            const response = await get(BASE_URL + '/countries?order=ASC');
            if (response && response.length > 0) {
                setCountryData(response);
            }
        }
        catch (ex) {
            alert(ex);
        }
    }

    function CreateWayPointFun() {
        if (wayPointType.type != 6) {
            if (wayPointName == '' || wayPointName == undefined) {
                setErrorMsg('Please Enter POI/Waypoint Title.');
                return;
            }
            if (waypointLat == '' || waypointLat == undefined) {
                setErrorMsg('Please Select Latitude and Longitude');
                return;
            }
            let json = {
                // "id": "string",
                "location": {
                    "latitude": parseFloat(waypointLat),
                    "longitude": parseFloat(waypointLong)
                },
                "name": wayPointName,
                "type": wayPointType && wayPointType.id ? wayPointType.type : 0,
                "description": description,
                "photo": wayPointImageName,
                "country": country
            }
            let tempWayPointData = [...wayPointData];
            tempWayPointData.push(json);
            setWayPointData(tempWayPointData);
            addMarkerInGPXMap(tempWayPointData);
        }

        setWayPointType({});
        setWayPointName("");
        setWayPointName("");
        setWayPointImageName("");
        setDescription("");
        setWayPointLocation("");
        setShowCountrySelect(false);
        setCountry({});
        setWayPointItem({});
        notify("POI/Waypoint created successfully");
        setShowAddWayPointPopUp(false);
    }

    function EditWayPointFun() {
        if (wayPointType.type != 6) {
            if (wayPointName === '' || wayPointName === undefined) {
                setErrorMsg('Please Enter POI/Waypoint Title.');
                return;
            }
            if (waypointLat === '' || waypointLat === undefined) {
                setErrorMsg('Please Select POI/Waypoint Location.');
                return;
            }

            let updatedWayPoint = {
                ...wayPointItem,
                location: {
                    latitude: parseFloat(waypointLat),
                    longitude: parseFloat(waypointLong)
                },
                name: wayPointName,
                type: wayPointType && wayPointType.id ? wayPointType.type : 0,
                description: description,
                photo: wayPointImageName,
                country: country
            };

            let tempWayPointData = [...wayPointData];

            if (wayPointIndex !== -1) {
                tempWayPointData[wayPointIndex] = updatedWayPoint;
                setWayPointData(tempWayPointData);
                addMarkerInGPXMap(tempWayPointData);
            }

        }
        setWayPointType({});
        setWayPointName("");
        setWayPointImageName("");
        setDescription("");
        setWayPointLocation("");
        setShowCountrySelect(false);
        setCountry({});
        setWayPointItem({});
        notify("POI/Waypoint edited successfully");
        setShowAddWayPointPopUp(false);
    }


    function addPoiInWaypoint(item) {
        // Check if the item already exists in poiArray
        const isDuplicate = poiArray.some(poi => poi.name === item.name);

        if (!isDuplicate) {
            // Add item to poiArray
            setPoiArray([...poiArray, item]);

            // Create json object for waypoint
            const json = {
                "id": item.id,
                "location": item.location,
                "name": item.name,
                "type": wayPointType && wayPointType.id ? wayPointType.type : 0,
                "description": item.description,
                "photo": item.photo,
                "country": country
            };

            // Add json object to wayPointData
            let tempWayPointData = [...wayPointData];
            tempWayPointData.push(json);
            setWayPointData(tempWayPointData);

            // Add marker in GPX map
            addMarkerInGPXMap(tempWayPointData);
        } else {
            console.log('Duplicate item found, not adding to poiArray');
        }
    }

    function filterPoisByCountry(countryName) {
        const data = poiData.filter(poi => poi.country && poi.country.name && poi.country.name.toLowerCase() === countryName.toLowerCase());
        setPoiDataOptions(data)
    }

    function handleUploadWayPointImg(url) {
        setWayPointImageName(url);
        setShowImagePopUp(false);
        setErrorMsg("");
    }

    return (
        <div className="bg-white rounded-3xl">
            <div className="bg-white rounded-3xl">
                <div className="flex justify-between items-center h-20" style={{ alignItems: 'flex-start' }}>
                    <Header category="" title={isEditPage === 'add' ? "ADD LOCAL POI\\WAYPOINT" : "EDIT LOCAL POI\\WAYPOINT"} />

                    <div>
                        <img src={CrossIcon} style={{ width: 30, height: 30, transform: "rotate(135deg)", cursor: 'pointer' }} onClick={() => { setShowAddWayPointPopUp(false); setWayPointType({}); setWayPointName(""); setWayPointImageName(""); setDescription(""); setWayPointLocation(""); setWayPointItem({}) }} alt="" />
                    </div>
                </div>
                <div className="countryFormMainDiv p-10">
                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 150 }}>
                        <div style={{
                            width: '50%',
                        }}>
                            <div>
                                <span style={{ fontSize: 20, color: '#000' }}>
                                    Types
                                </span>

                                <div className='InputBoxCss' style={{ width: 400, display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', padding: '5px 12px', marginTop: 10 }} onClick={() => { setShowWayPointTypeSelect(!showWayPointTypeSelect); setErrorMsg("") }}>
                                    {wayPointType && wayPointType.id ?
                                        <span style={{ color: '#000' }}>{wayPointType.name}</span>
                                        :
                                        <span style={{ color: 'gray' }}>Select Type</span>
                                    }
                                    <img src={DropDownArrow} style={{ width: 15, height: 15, transform: showWayPointTypeSelect ? "rotate(270deg)" : 'rotate(90deg)' }} />
                                </div>
                                {showWayPointTypeSelect &&
                                    <ClickAwayListener onClickAway={() => { setShowWayPointTypeSelect(false) }}>
                                        <div className="dropDownDiv" style={{ position: 'relative' }}>
                                            {wayPointTypeArray && wayPointTypeArray.length > 0 && wayPointTypeArray.map((item, index) => (
                                                <div className={wayPointType.id == item.id ? "countryDropDownItemActiveDiv" : "countryDropDownItemDiv"} onClick={() => { setWayPointType(item); setShowWayPointTypeSelect(false) }}>
                                                    {item.name}
                                                </div>
                                            ))}
                                        </div>
                                    </ClickAwayListener>
                                }
                            </div>

                            <div
                                style={{
                                    width: '60%',
                                    marginTop: '20px'
                                }}
                            >
                                <span style={{ fontSize: 20, color: '#000' }}>
                                    Country
                                </span>

                                <div className='InputBoxCss' style={{ width: 350, display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', padding: '5px 12px', marginTop: 10 }} onClick={() => { setShowCountrySelect(!showCountrySelect); setErrorMsg("") }}>
                                    {country && country.id ?
                                        <span style={{ color: '#000' }}>{country.name}</span>
                                        :
                                        <span style={{ color: 'gray' }}>Select Country</span>
                                    }
                                    <img src={DropDownArrow} style={{ width: 15, height: 15, transform: showCountrySelect ? "rotate(270deg)" : 'rotate(90deg)' }} />
                                </div>

                                {showCountrySelect &&
                                    <ClickAwayListener onClickAway={() => { setShowCountrySelect(false) }}>
                                        <div className="dropDownDiv" style={{ position: 'relative' }}>
                                            {countryData && countryData.length > 0 && countryData.map((item, index) => (
                                                <div className="countryDropDownItemDiv" onClick={() => { setCountry(item); setShowCountrySelect(false); filterPoisByCountry(item.name) }}>
                                                    {item.name}
                                                </div>
                                            ))}
                                        </div>
                                    </ClickAwayListener>
                                }
                            </div>

                            {wayPointType && wayPointType.type == 6 &&
                                <div style={{ marginTop: 20 }}>
                                    <span style={{ fontSize: 20, color: '#000' }}>
                                        Global POI
                                    </span>

                                    {poiArray && poiArray.length > 0 && poiArray.map((item, index) => (
                                        <div className="wayPointPoiChip">
                                            <div style={{ color: '#000' }}>{item.name} - Id: {item.friendly_id}</div>
                                            <div style={{ background: 'rgba(0,0,0,0.2)', borderRadius: 50, padding: 4, cursor: 'pointer' }} onClick={() => {
                                                let tempPoiArray = [...poiArray];
                                                tempPoiArray.splice(index, 1)
                                                setPoiArray(tempPoiArray)
                                            }}>
                                                <img src={CrossIcon} style={{ width: 10, height: 10, transform: "rotate(135deg)" }} alt="" />
                                            </div>
                                        </div>
                                    ))}
                                    <div className='InputBoxCss' style={{ width: 400, display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', padding: '5px 12px', marginTop: 10 }} onClick={() => { setShowPoiSelect(!showPoiSelect); setErrorMsg("") }}>
                                        <span style={{ color: 'gray' }}>Select POI</span>
                                        <img src={DropDownArrow} style={{ width: 15, height: 15, transform: showPoiSelect ? "rotate(270deg)" : 'rotate(90deg)' }} />
                                    </div>

                                    {showPoiSelect && (
                                        <ClickAwayListener onClickAway={() => { setShowPoiSelect(false) }}>
                                            <div className="dropDownDiv">
                                                {poiDataOptions && poiDataOptions.length > 0 && poiDataOptions.map((item, index) => {
                                                    const isItemSelected = poiArray.some(poi => poi.name === item.name);
                                                    return (
                                                        <div
                                                            key={index}
                                                            className={isItemSelected ? "countryDropDownItemActiveDiv" : "countryDropDownItemDiv"}
                                                            onClick={() => {
                                                                if (!isItemSelected) {
                                                                    addPoiInWaypoint(item);
                                                                }
                                                                setShowPoiSelect(false);
                                                            }}
                                                        >
                                                            <span>{item.name} - Id: {item.friendly_id}</span>
                                                            {isItemSelected && <img src={CheckIcon} style={{ width: 20, height: 20 }} alt="" />}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </ClickAwayListener>
                                    )}

                                </div>
                            }
                            {wayPointType && wayPointType.type == 6 ? null :
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', gap: 10, marginTop: 20 }}>
                                    <span style={{ fontSize: 20, color: '#000' }}>Title</span>
                                    <input type="text" className="InputBoxCss" style={{ width: 400 }} value={wayPointName} onChange={(e) => { setWayPointName(e.target.value) }} onFocus={() => { setErrorMsg("") }} />
                                </div>
                            }

                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', gap: 10, width: '40%' }}>
                            <span style={{ fontSize: 20, color: '#000' }}>Image</span>
                            {wayPointImageName && wayPointImageName != "" ?
                                <>
                                    <img src={wayPointImageName} style={{ width: 250, height: 150, borderRadius: 10 }} alt="" />
                                    <div className="changeImg" onClick={() => { setShowImagePopUp(true) }}>
                                        Change Image
                                    </div>
                                </>
                                :
                                <SimpleFileUpload
                                    apiKey={SimpleFileUploadAPIKey}
                                    onSuccess={handleUploadWayPointImg}
                                    width="350"
                                />
                            }
                        </div>
                    </div>
                    {wayPointType && wayPointType.type == 6 ? null :
                        <>
                            <div className="selectLatLongDiv">
                                <div style={{ marginTop: 0 }}>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', gap: 10, width: '60%' }}>
                                        <span style={{ fontSize: 20, color: '#000' }}>Location</span>
                                        {/* <input type="text" className="InputBoxCss" style={{ width: 350 }} placeholder="Select location on map" value={wayPointLocation} /> */}
                                        <input
                                            type='text'
                                            className='InputBoxCss'
                                            style={{ width: 350 }}
                                            placeholder='32.00, 34.00'
                                            value={waypointLat !== '' && waypointLong !== '' ? waypointLat + ", " + waypointLong : ''}
                                            onChange={e => {
                                                let latlng = e.target.value;
                                                if (e.target.value === '') {
                                                    setWaypointLat('');
                                                    setWaypointLong('');
                                                    if (marker3Ref.current != null) {
                                                        marker3Ref.current.remove();
                                                    }
                                                } else {
                                                    setWaypointLat(latlng);
                                                    if (marker3Ref.current != null) {
                                                        marker3Ref.current.remove();
                                                    }
                                                }
                                                latlng = latlng.trim();
                                                latlng = latlng.split(',');
                                                if (latlng.length === 2) {
                                                    setWaypointLat(latlng[0].trim());
                                                    setWaypointLong(latlng[1].trim());
                                                    marker3Ref.current = new mapboxgl.Marker()
                                                        .setLngLat([latlng[1].trim(), latlng[0].trim()])
                                                        .addTo(mapRef3.current);
                                                    map.flyTo({ center: [latlng[1].trim(), latlng[0].trim()], zoom: 7 });
                                                }
                                            }}
                                            onFocus={() => setErrorMsg('')}
                                        />
                                    </div>
                                    <div className="outerMapContainer" style={{ width: 600 }}>
                                        <div id="map3" style={{ width: '100%', height: '100%' }}></div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', gap: 10, marginTop: -20 }}>
                                <span style={{ fontSize: 20, color: '#000' }}>Description</span>
                                <textarea aria-invalid="false" id="txtDescription" rows="6" class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMultiline MuiOutlinedInput-inputMultiline InputBoxCss" style={{ width: 700 }} value={description} onChange={(e) => { setDescription(e.target.value) }} onFocus={() => (setErrorMsg(""))} />
                            </div>
                        </>
                    }

                    {errorMsg != '' &&
                        <div style={{ textAlign: 'center', color: 'red', fontSize: 16, marginTop: 30, }}>
                            {errorMsg}
                        </div>
                    }

                    {isEditPage === 'add' ?
                        <div className="AddBtn" onClick={() => { CreateWayPointFun() }}>
                            Add
                        </div> :
                        <div className="AddBtn" onClick={() => { EditWayPointFun() }}>
                            Edit
                        </div>
                    }
                </div>
            </div>


            {showImagePopUp && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        background: 'rgba(0,0,0,0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    onClick={() => { setShowImagePopUp(false) }}
                >
                    <div
                        style={{
                            background: '#fff',
                            borderRadius: 10,
                            padding: '15px'
                        }}
                    >
                        <SimpleFileUpload
                            apiKey={SimpleFileUploadAPIKey}
                            onSuccess={handleUploadWayPointImg}
                            width="350"
                        // onDrop={handleOnDrop}
                        />
                    </div>
                </div>
            )}

        </div>
    );
};

export default CreateWayPoints;
